export namespace AuthActions {
  export class LoginUser {
    static readonly type = 'AuthActions.LoginUser';

    constructor(
      public accessToken:  string) {}
  }

  export class SaveCookie {
    static readonly type = 'AuthActions.SaveCookie';
  }

  export class GetCookie {
    static readonly type = "AuthActions.GetCookie";
  }

  export class Logout {
    static readonly type = "AuthActions.Logout";
  }
}
