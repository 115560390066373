import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {  Observable } from 'rxjs';
import { CatalogData } from '../models/catalog.model';
import { ConfirmChangesModalComponent } from '../shared/components/confirm-changes-modal/confirm-changes-modal.component';
import { RefreshModalComponent } from '../shared/refresh-modal/refresh-modal.component';
import { CatalogState } from '../state/catalog/catalog.state';
import { CatalogService } from './catalog.service';

export enum SaveOptions {
  'settings',
  'categories',
  'mappings',
  'upload',
}
@Injectable({
  providedIn: 'root',
})
export class AuthHelperService {
  constructor(
    private store: Store,
    private catalogService: CatalogService,
    private zone: NgZone,
    private dialog: MatDialog,
    private router: Router
  ) {}

  checkTime(
    option: SaveOptions,
    newCatalogData: CatalogData,
    oldCatalogData: CatalogData
  ) {
    switch (option) {
      case SaveOptions.mappings:
        return (
          newCatalogData.optionalMappingsModificationDate.getTime() !=
          oldCatalogData.optionalMappingsModificationDate.getTime()
        );
      case SaveOptions.categories:
        return (
          newCatalogData.categoriesModificationDate.getTime() !=
          oldCatalogData.categoriesModificationDate.getTime()
        );
      case SaveOptions.settings:
        return (
          newCatalogData.settingsModificationDate.getTime() !=
          oldCatalogData.settingsModificationDate.getTime()
        );
      case SaveOptions.upload:
        return (
          newCatalogData.uploadDate.getTime() !=
          oldCatalogData.uploadDate.getTime()
        );
    }
    return false;
  }

  getDataHelper() {
    return new Observable((subs: any) => {
      const oldCatalogData = this.store.selectSnapshot(
        CatalogState.catalogData
      );
      let newCatalogData: CatalogData;
      this.catalogService.getCategoryState().subscribe((stateData) => {
        newCatalogData = stateData;
        if (
          this.checkTime(SaveOptions.upload, newCatalogData, oldCatalogData)
        ) {
          let dialogRef: MatDialogRef<RefreshModalComponent>;
          this.zone.run(() => {
            dialogRef = this.dialog.open(RefreshModalComponent, {
              width: '650px',
              autoFocus: true,
            });
          });
        } else {
          subs.next(true);
        }
      });
    });
  }

  saveSettingsHelper(option: SaveOptions) {
    return new Observable((subs: any) => {
      const oldCatalogData = this.store.selectSnapshot(
        CatalogState.catalogData
      );
      let newCatalogData: CatalogData;
      this.catalogService.getCategoryState().subscribe((stateData) => {
        newCatalogData = stateData;
        if (
          this.checkTime(SaveOptions.upload, newCatalogData, oldCatalogData)
        ) {
          let dialogRef: MatDialogRef<RefreshModalComponent>;
          this.zone.run(() => {
            dialogRef = this.dialog.open(RefreshModalComponent, {
              width: '650px',
              autoFocus: true,
            });
          });
        } else {
          if (this.checkTime(option, newCatalogData, oldCatalogData)) {
            let dialogRef: MatDialogRef<ConfirmChangesModalComponent>;
            this.zone.run(() => {
              dialogRef = this.dialog.open(ConfirmChangesModalComponent, {
                width: '650px',
                autoFocus: true,
              });
            });
            dialogRef.afterClosed().subscribe((res) => {
              if (res === 'continue') {
                subs.next(true);
              } else {
                subs.next(false);
              }
            });
            subs.next(false);
          } else {
            subs.next(true);
          }
        }
      });
    });
  }

  redirectTo(url: string) {
    this.zone.run(() => {
    this.router.navigateByUrl(url);
    });
  }
}
