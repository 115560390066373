import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryRoutingModule } from './category-routing.module';
import { UploadCatalogComponent } from './upload-catalog/upload-catalog.component';
import { CategoryContainerComponent } from './category-container/category-container.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { QuestionsComponent } from './questions/questions.component';
import { LinkComponent } from './upload-catalog/link/link.component';
import { ImportSettingsComponent } from './upload-catalog/import-settings/import-settings.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadCsvComponent } from './upload-catalog/upload-csv/upload-csv.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CatalogCategoriesComponent } from './catalog-categories/catalog-categories.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { QuestionComponent } from './catalog-categories/question/question.component';
import { MatRadioModule } from '@angular/material/radio';
import { PreprocessingProgressComponent } from './preprocessing-progress/preprocessing-progress.component';
import { ColumnsMappingComponent } from './columns-mapping/columns-mapping.component';
import { CatalogFooterComponent } from './catalog-footer/catalog-footer.component';
import { MandatoryColumnsComponent } from './columns-mapping/mandatory-columns/mandatory-columns.component';
import { StatusReportComponent } from './status-report/status-report.component';
import { RecommendedMappingsComponent } from './columns-mapping/recommended-mappings/recommended-mappings.component';
import { OptionalMappingsComponent } from './columns-mapping/optional-mappings/optional-mappings.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ConfirmMappingsModalComponent } from './columns-mapping/confirm-mappings-modal/confirm-mappings-modal.component';
import { NrNotMappedModalComponent } from './columns-mapping/nr-not-mapped-modal/nr-not-mapped-modal.component';
import { SendToTbOneComponent } from './send-to-tb-one/send-to-tb-one.component';
import { FileSaverModule } from 'ngx-filesaver';
import { PandaDownloadTemplateFooterComponent } from './catalog-categories/panda-download-template-footer/panda-download-template-footer.component';
import { MatButtonModule} from '@angular/material/button';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import { MatIconModule} from "@angular/material/icon";
import { PandaRowErrorsComponent } from './status-report/panda-row-errors/panda-row-errors.component';
import { UpdatesNotSavedModalComponent } from './status-report/updates-not-saved-modal/updates-not-saved-modal.component';

@NgModule({
  declarations: [
    UploadCatalogComponent,
    CategoryContainerComponent,
    QuestionsComponent,
    LinkComponent,
    ImportSettingsComponent,
    UploadCsvComponent,
    CatalogCategoriesComponent,
    QuestionComponent,
    PreprocessingProgressComponent,
    ColumnsMappingComponent,
    CatalogFooterComponent,
    MandatoryColumnsComponent,
    StatusReportComponent,
    RecommendedMappingsComponent,
    OptionalMappingsComponent,
    SendToTbOneComponent,
    ConfirmMappingsModalComponent,
    NrNotMappedModalComponent,
    PandaDownloadTemplateFooterComponent,
    PandaRowErrorsComponent,
    UpdatesNotSavedModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatProgressBarModule,
    MatTooltipModule,
    CategoryRoutingModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    ReactiveFormsModule,
    FileSaverModule,
    MatButtonModule,
    NgxMatSelectSearchModule,
    MatIconModule
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true, maxHeight: 800}}
  ]
})
export class CategoryModule {}
