import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { filter, map, take, tap } from 'rxjs';
import { CatalogData } from 'src/app/models/catalog.model';
import { StatusReportService } from 'src/app/services/status-report.service';
import { CatalogState } from '../catalog/catalog.state';
import { StatusReportActions } from './status-report.actions';
import { FileSaverService } from 'ngx-filesaver';

export interface StatusReportStateModel {
  statusReport: any;
}

export interface PandaRowErrorsStateModel {
  pandaRowErrors: any;
  updatePandaRowsStatus: any;
}

@State<StatusReportStateModel>({
  name: 'status',
  defaults: {
    statusReport: {},
  },
})

@Injectable()
export class StatusReportState {
  constructor(
    private store: Store,
    private statusReportService: StatusReportService,
    private fileSaver: FileSaverService
  ) {}

  @Selector()
  static statusReportData(state: StatusReportStateModel) {
    /*
  Mandatory - unmappedColumns,
  Recommended - totalRecommended and Mapped Recommened
  Optional - optionalMappings
  */
    return {
      mandatory: state.statusReport.unmappedColumns.length,
      totalRecommendedColumns: state.statusReport.totalRecommendedColumns,
      mappedRecommendedColumns: state.statusReport.mappedRecommendedColumns,
      optional: state.statusReport.optionalMappings,
      articlesOverCharLimit: state.statusReport.articlesOverCharLimit.length,
      nonUniqueArticles: state.statusReport.nonUniqueArticles.length,
      incorrectCompositionArticles: state.statusReport.incorrectCompositionArticles.length,
      invalidCheckDigitEAN: state.statusReport.invalidCheckDigitEAN.length,
      invalidLengthEAN: state.statusReport.invalidLengthEAN.length,
      nonNumericEAN: state.statusReport.nonNumericEAN.length
    };
  }

  @Selector()
  static pandaRowErrorsData(state: PandaRowErrorsStateModel) {
    return {
      headers: state.pandaRowErrors.headers,
      pandaRowErrors: state.pandaRowErrors.pandaRowErrors,
      totalPages: state.pandaRowErrors.totalPages
    };
  }

  @Selector()
  static updatePandaRowsStatus(state: PandaRowErrorsStateModel){
    return{
      updatePandaRowsStatus: state.updatePandaRowsStatus
    }
  }

  @Action(StatusReportActions.GetStatusReportData)
  getStatusReportData(ctx: StateContext<StatusReportStateModel>) {
    return this.store.select(CatalogState.catalogData).pipe(
      filter((catalogData) => catalogData.catalogId !== null),
      take(1),
      map((catalogData: CatalogData) =>
        this.statusReportService
          .getStatusReport(catalogData.catalogId)
          .subscribe((statusReport) => {
            ctx.patchState({ statusReport });
          })
      )
    );
  }

  @Action(StatusReportActions.GetPandaRowErrorsData)
  getFieldErrorsData(
    ctx: StateContext<PandaRowErrorsStateModel>,
    {
      pageNumber,
      pageSize}: StatusReportActions.GetPandaRowErrorsData){

     this.statusReportService
        .getFieldErrors(pageNumber,pageSize)
        .subscribe((pandaRowErrors) => {
          ctx.patchState({ pandaRowErrors });
        }
    )
  }

  @Action(StatusReportActions.DownloadStatusReport)
  downloadStatusReport() {
    const catalogId = this.store.selectSnapshot(
      CatalogState.catalogData
    ).catalogId;
    return this.statusReportService.downloadStatusReportFile(catalogId).pipe(
      tap((res: any) => {
        const name =
          res?.headers?.get('Content-Disposition') ||
          res?.headers?.get('content-disposition');
        this.fileSaver.save(res.body, name?.split('=')[1]);
      })
    );
  }

  @Action(StatusReportActions.UpdatePandaRowsValues)
  updatePandaRowsValues(
    ctx: StateContext<PandaRowErrorsStateModel>,
    {newPandaRowsValues}: StatusReportActions.UpdatePandaRowsValues) {
    this.statusReportService.updatePandaRowValues(newPandaRowsValues)
      .subscribe( response => { ctx.patchState({updatePandaRowsStatus: response});
      });
  }
}
