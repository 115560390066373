<h2 class="optional-columns--title">{{ "LABELS.OPTIONAL" | translate }}</h2>
<div class="error-container" *ngIf="optionalMappingErrors.length > 0">
  <div class="icon-container">
    <app-icon class="coral" name="error_circle_20_filled"></app-icon>
  </div>
  <div class="text-container">{{ optionalMappingErrors[0] | translate }}</div>
</div>
<div
  class="optional-columns"
  *ngFor="let column of optionalMappings; let i = index"
>
  <div class="column">
    <div class="column-title">
      <div class="add-array-item-button" (click)="deleteColumn(column, i)">
        <button  mat-stroked-button ><app-icon name="delete_24_regular" class="primary"></app-icon></button>
      </div>
      <h3 class="optional-mapping--name">
        {{ "COLUMNS_MAPPINGS.LABELS.OPTIONAL_COLUMN" | translate }} {{ i + 1 }}
      </h3>
    </div>
    <div class="select">
      <div class="label">
        {{ "COLUMNS_MAPPINGS.LABELS.COLUMN_NAME" | translate }}
      </div>
      <ng-template [ngIf]="column.disabled" [ngIfElse]="normalSelect">
        <mat-select
          required
          [(ngModel)]="optionalMappings[i].columnName"
          [disabled]="column.disabled"
        >
          <mat-option [value]="-1" [disabled]="true">{{
            "COLUMNS_MAPPINGS.LABELS.SELECT_OPTION" | translate
          }}</mat-option>

          <mat-option *ngFor="let option of columnNames" [value]="option">{{
            option
          }}</mat-option>
        </mat-select>
      </ng-template>
      <ng-template #normalSelect>
        <mat-select
          required
          [(ngModel)]="optionalMappings[i].columnName"
          [disabled]="column.disabled"
          (selectionChange)="selectionChanged($event)"
          [class]="'select-dropdown'"
          [ngClass]="{'invalid': column.columnName===''}"
          (click)="initializeFilteredList()"
          disableOptionCentering
        >
          <mat-option>
            <ngx-mat-select-search [formControl]="optionalColumnFilterCtrl"
                                   placeholderLabel='{{"CATALOG_CATEGORIES.SEARCH_BAR_PLACEHOLDER" | translate }}'
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let option of optionalColumnNames"
            [value]="option"
            [disabled]="!isFiltered(option)"
            [class.hide]="!isFiltered(option)"
            (onSelectionChange)="optionSelected($event, i)"
            >{{ option }}</mat-option
          >
        </mat-select>
      </ng-template>
    </div>
    <div class="description">
      <div class="label">
        {{ "COLUMNS_MAPPINGS.LABELS.PANDA_COLUMN" | translate }}
      </div>
      <div class="pandaColumn" >{{ getPandaColumn(column) }}</div>
    </div>
    <div class="description">
      <div class="label">
        {{ "COLUMNS_MAPPINGS.LABELS.DESCRIPTION" | translate }}
      </div>
      <input matInput
             [(ngModel)]="column.description"
             [ngClass]="{'invalid': column.description===''}"
      />
    </div>
    <div class="radio-group">
      <div class="label">
        {{ "COLUMNS_MAPPINGS.LABELS.TYPE_OF_COLUMN" | translate }}
      </div>
      <mat-radio-group
        aria-label="Select an option"
        [(ngModel)]="column.mappingType"
        (change)="radioChange($event, i)"
      >
        <mat-radio-button value="ATTRIBUTE">{{
          "COLUMNS_MAPPINGS.LABELS.ATTRIBUTE" | translate
        }}</mat-radio-button>
        <mat-radio-button value="COMPONENT">{{
          "COLUMNS_MAPPINGS.LABELS.COMPONENT" | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <hr *ngIf="i !== optionalMappings.length - 1" />
</div>
<app-button
  (click)="addOptionalMappingForm()"
  text="COLUMNS_MAPPINGS.LABELS.ADD_OPTIONAL_INFO"
  type="stroked"
></app-button>

