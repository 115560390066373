<h1 *ngIf="templateFlow==false" >{{ "CATALOG_CATEGORIES.TITLE" | translate }}</h1>
<h1 *ngIf="templateFlow==true" >{{ "CATALOG_CATEGORIES.TITLE_DOWNLOAD_TEMPLATE" | translate }}</h1>

<div class="sub-text">
  {{ "CATALOG_CATEGORIES.SUB-TEXT" | translate  }}
</div>
<div class="info">
  {{ "CATALOG_CATEGORIES.INFO" | translate }}
</div>

<div class="error-container" *ngIf="showErrors && !categoriesCorrect">
  <div class="icon-container">
    <app-icon class="coral" name="error_circle_20_filled"></app-icon>
  </div>
  <div class="text-container">{{ "CATALOG_CATEGORIES.ERROR" | translate }}</div>
</div>
<div class="catalog-categories">
  <div class="category-groups">
    <div class="search-bar-container">
      <input id="cat_input_search_category" matInput [formControl]="searchBarControl"
             placeholder='{{ "CATALOG_CATEGORIES.SEARCH_BAR_PLACEHOLDER" | translate }}...' />
    </div>
    <div class="category-groups--list">
      <div
        id="cat_btn_cat_group_{{categoryGroup.slug}}"
        class="category-groups--item"
        [ngClass]="{ selected: categoryGroup.id === viewedCategory?.categoryId }"
        *ngFor="let categoryGroup of categoryGroups; let i = index"
        (click)="changeSelectedCategory(categoryGroup.id)"
      >
        {{ "CATALOG_CATEGORIES.CATEGORY_GROUP."+categoryGroup.slug| translate }}
        <span class="error-icon" *ngIf="showErrors && !categoryGroup.valid">
          <app-icon class="coral" name="error_circle_20_filled"></app-icon>
        </span>
        <span class="icon">
          <app-icon name="chevron_right_20_regular"></app-icon>
        </span>
        <span
          class="selected-count"
          *ngIf="
            categoryGroup.id === viewedCategory?.categoryId ||
            selectedCount[categoryGroup.id] > 0
          "
        >
          {{
          "CATALOG_CATEGORIES.SELECTED"
            | translate: { number: selectedCount[categoryGroup.id] }
          }}
        </span>
      </div>
    </div>
  </div>
  <div class="selected-category-container">
    <div class="selected-category--item select-all" [ngClass]="{'disabled' : searchMode}" >
      <mat-checkbox
        id="cat_checkbox_category_all"
        class="checkbox"
        [checked]="allCategoriesSelected"
        [indeterminate]="allCategoriesIdeterminate"
        (change)="toogleAll()"
        [disabled]="searchMode"
      ></mat-checkbox>
      {{ "LABELS.SELECT_ALL" | translate }}
    </div>
  <div
    class="selected-category"
    [formGroup]="form"
    *ngIf="categories && !searchMode"
  >
    <div class="selected-category--list" formArrayName="categories">
      <div
        class="selected-category--item"
        *ngFor="
          let category of viewedCategory?.categories;
          let i = index
        "
        [formGroupName]="i"
        [ngClass]="{
          error:
            showErrors &&
            category.questionAnswer === null &&
            category.questionSlug !== null &&
            category.selected
        }"
      >
        <mat-checkbox
          id="cat_checkbox_category_{{category.slug}}"
          class="checkbox"
          formControlName="value"
          (change)="changeSelectedCategoryValue(category)"
        ></mat-checkbox>
        {{ "CATALOG_CATEGORIES.CATEGORY." + category?.slug | translate }}
        <ng-template [ngIf]="category.selected && category.questionSlug">
          <app-question [category]="category" [categoryId]="i" [searchModeInd]="searchMode"></app-question>
        </ng-template>
      </div>
    </div>
  </div>

  <div
    class="selected-category"
    [formGroup]="form"
    *ngIf="searchMode && searchResults?.categories.length > 0"
  >
    <div class="selected-category--list" formArrayName="categories">
      <div
        class="selected-category--item"
        *ngFor="
          let category of searchResults?.categories;
          let i = index
        "
        [formGroupName]="i"
        [ngClass]="{
          error:
            showErrors &&
            category.questionAnswer === null &&
            category.questionSlug !== null &&
            category.selected
        }"
      >
        <mat-checkbox
          id="cat_checkbox_category_{{category.slug}}"
          class="checkbox"
          formControlName="value"
          (change)="changeSelectedCategoryValue(category, category.categoryGroupId)"
        ></mat-checkbox>
        {{ "CATALOG_CATEGORIES.CATEGORY." + category?.slug | translate }}
        <ng-template [ngIf]="category.selected && category.questionSlug">
          <app-question [category]="category" [categoryId]="i" [searchModeInd]="searchMode"></app-question>
        </ng-template>
      </div>
    </div>
  </div>

  <div
    class="selected-category"
    *ngIf="searchMode && searchResults?.categories.length === 0"
  >
    <div class="selected-category--list" >
      {{ "CATALOG_CATEGORIES.NO_RESULTS" | translate }}
    </div>
  </div>
</div>
</div>

<app-catalog-footer *ngIf="templateFlow==false" (event)="nextStep($event)"></app-catalog-footer>

<app-panda-download-template-footer *ngIf="templateFlow==true"></app-panda-download-template-footer>
