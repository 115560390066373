<div class="question-container">
  <div class="question">
    {{ questionText | translate }}
  </div>
  <div class="answer">
    <mat-radio-group
      (change)="changeQuestionAnswer($event)"
      [value]="category.questionAnswer"
    >
      <mat-radio-button [value]="true">{{
        "LABELS.YES" | translate
      }}</mat-radio-button>
      <mat-radio-button [value]="false">
        {{ "LABELS.NO" | translate }}</mat-radio-button
      >
    </mat-radio-group>
  </div>
</div>
