<div class="get-started">
  <div>
  <h1 class="title"> {{ "GET_STARTED_PAGE.HOW_IT_WORKS.TITLE" | translate }}</h1>
  </div>
  <div class="description">
    <p>{{ "GET_STARTED_PAGE.HOW_IT_WORKS.DESCRIPTION" | translate }}</p>
  </div>
  <div class="info-tabs">
    <div class="info-tab">
      <i class="icon-info">
        <img class="info-icon" src="assets/icons/TB_Icon_Order_line.svg">
      </i>
      <div class="text">
        <h2>{{ "GET_STARTED_PAGE.HOW_IT_WORKS.INFO_CHOOSE_OPTION.TITLE" | translate }}</h2>
        <p>{{ "GET_STARTED_PAGE.HOW_IT_WORKS.INFO_CHOOSE_OPTION.INFO" | translate }}</p>
      </div>
    </div>
    <div class="info-tab">
      <i class="icon-info">
        <img src="assets/icons/TB_Icon_Brand_line.svg">
      </i>
      <div class="text">
        <h2>{{ "GET_STARTED_PAGE.HOW_IT_WORKS.INFO_SELECT_CATEGORY.TITLE" | translate }}</h2>
        <p>{{ "GET_STARTED_PAGE.HOW_IT_WORKS.INFO_SELECT_CATEGORY.INFO" | translate }}</p>
      </div>
    </div>
    <div class="info-tab">
      <i class="icon-info">
        <img src="assets/icons/TB_Icon_Transactions_line.svg">
      </i>
      <div class="text">
        <h2>{{ "GET_STARTED_PAGE.HOW_IT_WORKS.INFO_MAPPING.TITLE" | translate }}</h2>
        <p>{{ "GET_STARTED_PAGE.HOW_IT_WORKS.INFO_MAPPING.INFO" | translate }}</p>
      </div>
    </div>
    <div class="info-tab">
      <i class="icon-info">
        <img src="assets/icons/TB_Icon_Report_line.svg">
      </i>
      <div class="text">
        <h2>{{ "GET_STARTED_PAGE.HOW_IT_WORKS.INFO_DOWNLOAD_REPORT.TITLE" | translate }}</h2>
        <p>{{ "GET_STARTED_PAGE.HOW_IT_WORKS.INFO_DOWNLOAD_REPORT.INFO" | translate }}</p>
      </div>
    </div>
    <div class="info-tab">
      <i class="icon-info">
        <img src="assets/icons/TB_Icon_DownloadFile_line.svg">
      </i>
      <div class="text">
        <h2>{{ "GET_STARTED_PAGE.HOW_IT_WORKS.INFO_DOWNLOAD_PANDA.TITLE" | translate }}</h2>
        <p>{{ "GET_STARTED_PAGE.HOW_IT_WORKS.INFO_DOWNLOAD_PANDA.INFO" | translate }}</p>
      </div>
    </div>
  </div>


  <app-get-started-options (event)="nextStep($event)"></app-get-started-options>
</div>
