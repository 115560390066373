import {PandaRowUpdateData} from "@app/models/pandaRowUpdateData";

export namespace StatusReportActions {
  export class GetStatusReportData {
    static readonly type = 'StatusReportActions.GetStatusReportData';
  }
  export class DownloadStatusReport {
    static readonly type = "StatusReportActions.DownloadStatusReport";
  }
  export class GetPandaRowErrorsData {
    static readonly  type ="StatusReportActions.getPandaRowErrorsData";

    constructor(public pageNumber: number, public pageSize: number) {}
  }
  export class UpdatePandaRowsValues {
    static readonly type="StatusReportActions.UpdatePandaRowsValues";
    constructor(public newPandaRowsValues: PandaRowUpdateData[]) {}
  }
}
