import { Component, Input } from '@angular/core';
import {ATTRIBUTE_TYPE} from "@app/models/attribute.type";

@Component({
  selector: 'app-recommended-mappings',
  templateUrl: './recommended-mappings.component.html',
  styleUrls: ['./recommended-mappings.component.scss']
})
export class RecommendedMappingsComponent  {
  @Input() recommendedColumns: any[] = [];
  @Input() columnNames: string[] = [];
  @Input() userLang: number;
  @Input() topArrayMappings: Map<number,number>;

  constructor() { }

  static readonly MAX_ARRAY_ITEMS: number = 5;

  addMapping(column: any, i: number) {
    let mapping = this.recommendedColumns[i];

    // clone the selected mapping
    let newMapping = JSON.parse(JSON.stringify(mapping));

    // initially unmapped
    newMapping.option = -1;

    // increment the index
    newMapping.arrayIndex = newMapping.arrayIndex + 1;

    // set the displayed column name. Dependens on language and, for array type, the index
    newMapping.displayColumn = this.getDisplayColumn(newMapping, this.userLang);

    //  Record the new biggest m,apped index
    this.topArrayMappings.set(column.attribute.id, newMapping.arrayIndex);

    // insert the new mapping at the appropriate plce in the mappings array
    this.recommendedColumns.splice(i + 1, 0, newMapping);
  }

  deleteMapping(column: any, i: number) {
    this.recommendedColumns.splice(i, 1);
    var topIndex = this.topArrayMappings.get(column.attribute.id);
    this.topArrayMappings.set(column.attribute.id, topIndex-1);
  }

  shouldDisplayAdd (column: any) : boolean {
    return column.attribute.attributeType == ATTRIBUTE_TYPE.ARRAY
      && column.arrayIndex == this.topArrayMappings.get(column.attribute.id)
      && column.arrayIndex < RecommendedMappingsComponent.MAX_ARRAY_ITEMS -1
      && column.option != -1
  }

  shouldDisplayDelete (column: any) : boolean {
    return column.attribute.attributeType == ATTRIBUTE_TYPE.ARRAY
      && column.arrayIndex == this.topArrayMappings.get(column.attribute.id)
      && column.arrayIndex != 0
  }

  getDisplayColumn(mappingValue: any, userLang: number): string {
    var displayColumn
    if (userLang == 0) {
      displayColumn = mappingValue.columnNameDE;
    }
    else {
      displayColumn = mappingValue.columnName;
    }

    // Will always be true currently since we only add array types
    if (mappingValue.attributeType == ATTRIBUTE_TYPE.ARRAY) {
      displayColumn = displayColumn + '{' + mappingValue.arrayIndex + '}';
    }

    return displayColumn;
  }

}
