import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CatalogData } from 'src/app/models/catalog.model';
import { OptionalMapping } from 'src/app/models/optional-mappings';
import { CatalogState } from 'src/app/state/catalog/catalog.state';
import { CategoryMappingsActions } from 'src/app/state/category/category-mappings/category-mappings.actions';
import { CategoryMappingsState } from 'src/app/state/category/category-mappings/category-mappings.state';
import { ConfirmMappingsModalComponent } from './confirm-mappings-modal/confirm-mappings-modal.component';
import { NrNotMappedModalComponent } from './nr-not-mapped-modal/nr-not-mapped-modal.component';
import {CatalogMapping} from "@app/models/catalog-mappings";

@Component({
  selector: 'app-columns-mapping',
  templateUrl: './columns-mapping.component.html',
  styleUrls: ['./columns-mapping.component.scss'],
})
export class ColumnsMappingComponent implements OnInit, OnDestroy {
  @Select(CategoryMappingsState.attributesMapping)
  attributes: Observable<any>;
  @Select(CategoryMappingsState.optionalMappings)
  optionalMappings$: Observable<OptionalMapping[]>;
  @ViewSelectSnapshot(CategoryMappingsState.columnNames) columnNames: string[];
  @Select(CatalogState.catalogData) catalogData$: Observable<CatalogData>;
  @ViewChild('mandatory', { static: false }) mandatoryRef: ElementRef;
  @ViewChild('recommended', { static: false }) recommendedRef: ElementRef;
  @ViewChild('optional', { static: false }) optionalRef: ElementRef;
  columns = [
    { name: 'LABELS.MANDATORY', id: 'mandatory' },
    { name: 'LABELS.RECOMMENDED', id: 'recommended' },
    { name: 'LABELS.OPTIONAL', id: 'optional' },
  ];
  a_nr_name = "a_nr";
  p_nr_name = "p_nr";
  subscriptions: Subscription = new Subscription();
  mandatoryData: any[];
  recommendedData: any[];
  topArrayMappings: Map<number,number>;
  optionalMappings: OptionalMapping[] = [];
  popupOpened = false;
  optionalMappingErrors: Array<string> = [];
  userLang: number[];

  constructor(private store: Store, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.catalogData$.subscribe((data) => {
        this.userLang = [data.user.userLang];
        if (data.catalogId && !!this.attributes)
          this.store.dispatch(
            new CategoryMappingsActions.GetInitialData(data?.catalogId)
          );
      })
    );
    this.subscriptions.add(
      this.attributes.subscribe((data) => {
        this.mandatoryData = data?.mandatory;
        this.recommendedData = data?.recommended;
        this.topArrayMappings = data?.topArrayMappings;
        // console.log("recommended", data?.recommended)
      })
    );
    this.subscriptions.add(
      this.optionalMappings$.subscribe((data) => {
        this.optionalMappings = [...data];
      })
    );
  }

  goToColumn(id: string) {
    if (id === this.columns[0].id) {
      this.mandatoryRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: "start",
        inline: "nearest",
      });
    } else if (id === this.columns[1].id) {
      this.recommendedRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: "nearest",
      });
    } else if (id === this.columns[2].id) {
      this.optionalRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: "nearest",
      });
    }
  }

  nextStep(nextStep: number) {
    this.optionalMappingErrors.splice(0);
    this.popupOpened = true;

    // c.arrayIndex <= 0 because only non-array attrs or array attrIndex 0 are mandatory
    const emptyColumns = [...this.mandatoryData.filter((c) => c.option === -1 && c.arrayIndex <= 0)];

    const colsUsedInMandatoryMapping = this.mandatoryData.filter((c) => c.option != -1).map((c) => c.option);
    const colsUsedInRecommendedMapping = this.recommendedData.filter((c) => c.option != -1).map((c) => c.option);

    const badOptionalMappings = this.optionalMappings.filter(
      (mapping) => mapping.description == '' || mapping.columnName == ''
    );

    if (badOptionalMappings.length > 0) {
      this.optionalMappingErrors.push("COLUMNS_MAPPINGS.OPTIONAL_MAPPIMG_MANDATORY_ERROR")
      this.goToColumn("optional");
      return;
    }

    let alreadyMappedColumns = this.optionalMappings
      .filter(
      (mapping) =>
        colsUsedInMandatoryMapping.includes(mapping.columnName)
        ||
        colsUsedInRecommendedMapping.includes(mapping.columnName) )
      .map((c) => c.columnName ) ;

    // remove any duplicates
    alreadyMappedColumns = alreadyMappedColumns.filter((item, index) => alreadyMappedColumns.indexOf(item) === index);

    const mapForMultiplesCheck: { [id: string]: string; } = {};
    let multipleOptMappings: Array<string> = [];

    for (var mapping of this.optionalMappings) {
      if (mapForMultiplesCheck[mapping.columnName]) {
        // Map already contained it - so we must be trying to optional-map this column twice
        multipleOptMappings.push(mapping.columnName);
      }
      mapForMultiplesCheck[mapping.columnName] = mapping.columnName;
    }

    if(this.nrColumnsNotMapped(emptyColumns)){
      this.dialog.open(NrNotMappedModalComponent);
    } else{
      const dialogConfig = new MatDialogConfig();

      dialogConfig.data = {
        emptyColumns: emptyColumns,
        alreadyMappedColumns: alreadyMappedColumns,
        multipleOptMappings: multipleOptMappings,
        disableNext: alreadyMappedColumns.length > 0 || multipleOptMappings.length > 0
      };

      if (emptyColumns.length > 0 || alreadyMappedColumns.length > 0 || multipleOptMappings.length > 0)  {
        const dialogRef = this.dialog.open(ConfirmMappingsModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result === 'save') {
            this.sendData(nextStep);
          }
        });
      } else {
        this.sendData(nextStep);
      }
    }
  }

  nrColumnsNotMapped(emptyColumns: any[]){
    const nrNotMapped = emptyColumns.filter((c) => c.columnName === this.p_nr_name || c.columnName === this.a_nr_name);
    return nrNotMapped.length > 0;
  }

  sendData(nextStep: number) {
    const finalData = [
      ...this.mapColumnData(this.mandatoryData),
      ...this.mapColumnData(this.recommendedData),
    ];

    let catalogMapping = new CatalogMapping();

    catalogMapping.generalMappings = finalData;
    catalogMapping.optionalMappings = this.optionalMappings;
    this.store.dispatch(new CategoryMappingsActions.SetMappings(catalogMapping));
  }

  mapColumnData(dataList: any[]) {
    const finalData: any[] = [];
    dataList
      .filter((data) => data.option !== -1)
      .forEach((field) => {
        if (field?.mappingId) {
          finalData.push({
            attributeId: field.id,
            option: field.option,
            catalogId: field.catalogId,
            mappingId: field.mappingId,
            arrayIndex: field.arrayIndex,
            isUserMapped: field.isUserMapped,
          });
        } else {
          finalData.push({
            attributeId: field.id,
            option: field.option,
            catalogId: field.catalogId,
            arrayIndex: field.arrayIndex,
            isUserMapped: field.isUserMapped,
          });
        }
      });
    return finalData;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
