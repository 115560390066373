<div class="confirm-modal">

  <span>
    <h3>
      {{
      "STATUS_REPORT.UPDATES_NOT_SAVED_MODAL.INFO" | translate }}
    </h3>

  </span>
  <span class="sub-text">
    <li *ngFor="let row of pandaRowUpdates">{{rowToString(row)}}</li>
  </span>
  <span>
    <h3>
      {{
      "STATUS_REPORT.UPDATES_NOT_SAVED_MODAL.OUTRO" | translate }}
    </h3>
  </span>

  <div class="buttons">
    <div class="button-container">
      <app-button
        id="stat_rep_confirm_modal_btn_keep_edit"
        (click)="keepEditing()"
        type="stroked"
        text="STATUS_REPORT.UPDATES_NOT_SAVED_MODAL.KEEP_EDITING_BTN"
      ></app-button>
    </div>
    <div class="button-container">
      <app-button
        id="stat_rep_confirm_modal_btn_continue"
        (click)="continue()"
        color="warn"
        text="STATUS_REPORT.UPDATES_NOT_SAVED_MODAL.CONTINUE_BTN"
      ></app-button>
    </div>
  </div>
</div>
