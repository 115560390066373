<div class="banner-container">
  <div class="logo-container"><img src="/assets/send_logo.svg" /></div>
  <div class="text-container">
    <h1>{{ "SEND_TO_TB_ONE.TITLE" | translate }}</h1>
    <div class="text">{{ "SEND_TO_TB_ONE.SUB_TITLE" | translate }}</div>
    <div class="info">{{ "SEND_TO_TB_ONE.INFO" | translate }}</div>
  </div>
  <div class="button-container">
    <app-button
      id="export_btn_to_tbone"
      [text]="'SEND_TO_TB_ONE.BUTTON'"
      [disabled]="true"
    ></app-button>
  </div>
  <div class="new-image-container">
    <img src="/assets/illustration.svg" />
  </div>
</div>
<div class="banner-container">
  <div class="text-container">
    <h1>{{ "DOWNLOAD_PANDA.TITLE" | translate }}</h1>
    <div class="text">{{ "DOWNLOAD_PANDA.SUB_TITLE" | translate }}</div>
  </div>
  <div class="button-container">
    <app-button
      id="export_btn_download_panda"
      [text]="'DOWNLOAD_PANDA.BUTTON'"
      (click)="getFile()"
    ></app-button>
  </div>
  <div class="image-container">
    <img src="/assets/illustration.svg" />
  </div>
</div>
