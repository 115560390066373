import { Component, HostListener, OnInit } from '@angular/core';
import { SelectSnapshot, ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AuthActions } from './state/auth/auth.actions';
import { NavigationState } from './state/navigation/navigation.state';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewSelectSnapshot(NavigationState.loading) loading: boolean;
  // loading = true;
  title = 'import-wizard-client';

  constructor(private store: Store,private snackBar: MatSnackBar,) {}

  // listen to mouse click anywhere on page. If clicked close snackBar - snackbar are used for our errors display.
  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    this.closeSnackbar();
  }
  ngOnInit(): void {
    this.store.dispatch(new AuthActions.GetCookie());
  }

  closeSnackbar(): void {
    this.snackBar.dismiss();
  }
}
