import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CatalogService } from 'src/app/services/catalog.service';
import { CatalogActions } from 'src/app/state/catalog/catalog.actions';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  constructor(private store: Store) { }

  ngOnInit(): void {
      // setInterval(() => {
      //   this.store.dispatch(new CatalogActions.UpdatePreprocessingStatus())
      // }, 500)
  }

}
