import { AuthState } from './auth/auth.state';
import { CatalogState } from './catalog/catalog.state';
import { CategoryMappingsState } from './category/category-mappings/category-mappings.state';
import { CategoryState } from './category/category.state';
import { NavigationState } from './navigation/navigation.state';
import { StatusReportState } from './status-report/status-report.state';

export const States = [
  AuthState,
  NavigationState,
  CatalogState,
  CategoryState,
  CategoryMappingsState,
  StatusReportState
];
