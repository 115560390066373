import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {PandaRowUpdateData} from "@app/models/pandaRowUpdateData";

@Component({
  selector: 'app-updates-not-saved-modal',
  templateUrl: './updates-not-saved-modal.component.html',
  styleUrls: ['./updates-not-saved-modal.component.scss']
})
export class UpdatesNotSavedModalComponent{

  pandaRowUpdates: PandaRowUpdateData[];
  constructor(
    public dialogRef: MatDialogRef<UpdatesNotSavedModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ){
    this.pandaRowUpdates = data.pandaRowUpdates;
  }

  keepEditing() {
    this.dialogRef.close("keepEditing");
  }

  continue() {
    this.dialogRef.close("continue");
  }

  rowToString(row: PandaRowUpdateData){
    let rowString = "";
    const rowKeys = Object.keys(row);
    rowKeys.forEach((key) => {
      rowString = rowString + key + ": " + row[key] + "; ";
    } )

    return rowString;
  }
}
