import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PandaTemplateService {
  baseUrl = environment.apiUrl + '/catalog';

  public static downloadUrl =  'pandaTemplate/download';

  constructor(private http: HttpClient) {}

  downloadPandaTemplateFile(catalogId: number) {
    return this.http.get(`${this.baseUrl}/${catalogId}/${PandaTemplateService.downloadUrl}`, {
      responseType: 'blob',
      observe: 'response',
      withCredentials: !environment.disableCsrf
    });
  }
}
