import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { CatalogData } from '../models/catalog.model';
import { CatalogActions } from '../state/catalog/catalog.actions';
import { CatalogState } from '../state/catalog/catalog.state';

@Injectable({
  providedIn: 'root',
})
export class CatalogDataResolver implements Resolve<CatalogData> {
  constructor(private store: Store) {}
  resolve(): Observable<CatalogData> {
    return this.store.dispatch([
      new CatalogActions.GetStatusBar()
    ])
  }
}
