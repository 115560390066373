import { Component, OnInit } from '@angular/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { Node, NodeStatus, StatusBar } from 'src/app/models/status-bar.model';
import { CatalogActions } from 'src/app/state/catalog/catalog.actions';
import { CatalogState } from 'src/app/state/catalog/catalog.state';
import {NavigationState} from "@app/state/navigation/navigation.state";

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
})
export class StatusBarComponent implements OnInit {
  @ViewSelectSnapshot(CatalogState.statusBar) statusBar: StatusBar;
  @ViewSelectSnapshot(NavigationState.templateFlow) templateFlow: boolean;

  constructor(public store: Store) {}

  ngOnInit(): void {}

  navigateToStep(node: Node) {
    if (node.status !== NodeStatus.NOT_COMPLETED) {
      this.store.dispatch(new CatalogActions.GoToStep(node.order));
    }
  }
}
