import {CatalogMapping} from "@app/models/catalog-mappings";

export namespace CategoryMappingsActions {
  export class GetMandatoryAttributes {
    static readonly type = 'CategoryActions.GetMandatoryAttributes';

    constructor(public catalogId: number) {}
  }

  export class GetRecomendedAttributes {
    static readonly type = 'CategoryActions.GetRecomendedAttributes';

    constructor(public catalogId: number) {}
  }

  export class GetCatalogMappings {
    static readonly type = 'CategoryActions.GetCatalogMappings';
    constructor(public catalogId: number) {}
  }

  export class UpdateOptionalMappings {
    static readonly type = 'CategoryActions.UpdateOptionalMappings';
    constructor(public optionalMappings: any[]){}
  }

  export class GetRecommendedMappings {
    static readonly type = 'CategoryActions.GetRecommendedMappings';
    constructor(public catalogId: number) {}
  }

  export class GetColumnNames {
    static readonly type = 'CategoryActions.GetColumnNames';
    constructor(public catalogId: number) {}
  }

  export class GetInitialData {
    static readonly type = 'CategoryActions.GetInitialData';
    constructor(public catalogId: number) {}
  }

  export class SetCatalogMappings {
    static readonly type = 'CategoryActions.SetCatalogMappings';

    constructor(public catalogMapping: CatalogMapping) {}
  }

  export class SetMappings {
    static readonly type = 'CategoryActions.SetMappings';

    constructor(public catalogMapping : CatalogMapping){}
  }
  export class DeleteOptionalMapping {
    static readonly type = 'CategoryMappingsActions.DeleteOptionalMapping';

    constructor (public columnName: string, public catalogId: number ) {}
  }
}
