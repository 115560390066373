import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    return Math.round(value / 1000 / 1000) + ' MB';
  }
}
