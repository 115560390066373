import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() name: string;
  @Input() class: string = '';
  src: any;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    // this.src = `@fluentui/svg-icons/icons/${this.name}.svg`
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
      `assets/icons/${this.name}.svg`
    );
  }
}
