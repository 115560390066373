import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, finalize, map, Observable, tap } from 'rxjs';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AuthState } from '../state/auth/auth.state';
import { Store } from '@ngxs/store';
import { AuthActions } from '../state/auth/auth.actions';
import { NavigationState } from '../state/navigation/navigation.state';
import { NavigationActions } from '../state/navigation/navigation.actions';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  @SelectSnapshot(NavigationState.loading) loading: boolean;

  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // next.handle(request).subscribe(() => {
    //   this.store.dispatch(
    //     new NavigationActions.RemoveFromLoadingQue(request.url)
    //   );
    // });
    this.store.dispatch(new NavigationActions.AddToLoadingQue(request.url));
    return next.handle(request).pipe(
      finalize(() =>
        this.store.dispatch(
          new NavigationActions.RemoveFromLoadingQue(request.url)
        )),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.store.dispatch(new AuthActions.Logout());
        }
        throw error;
      })
    );
  }
}
