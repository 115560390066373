import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { AuthActions } from '../state/auth/auth.actions';
import { AuthState } from '../state/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  @Select(AuthState.accessToken) accessToken$: Observable<string>;

  constructor(private router: Router, private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.accessToken$.pipe(
      map((token: string) => {
        if (!token) {
         this.store.dispatch(new AuthActions.Logout())
          return false;
        } else {
          return !!token;
        }
      })
    );
  }
}
