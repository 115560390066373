import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './components/input/input.component';
import { LabelComponent } from './components/label/label.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from './components/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ButtonComponent } from './components/button/button.component';
import { MatButtonModule } from '@angular/material/button';
import { FileSizePipe } from './pipes/file-size.pipe';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { MappingNamePipe } from './pipes/mapping-name.pipe';
import { ConfirmChangesModalComponent } from './components/confirm-changes-modal/confirm-changes-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RefreshModalComponent } from './refresh-modal/refresh-modal.component';
export const CustomComponents = [
  InputComponent,
  LabelComponent,
  IconComponent,
  ButtonComponent,
  FileSizePipe,
  MappingNamePipe,
  DragAndDropDirective,
  ConfirmChangesModalComponent,
];

export const ExportedModules = [
  TranslateModule,
  MatFormFieldModule,
  MatDialogModule,
  ConfirmChangesModalComponent,
];

@NgModule({
  declarations: [...CustomComponents, RefreshModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
    TranslateModule,
    RouterModule,
  ],
  exports: [...CustomComponents, ...ExportedModules],
})
export class SharedModule {}
