<div class="container">
  <div class="content">
    <div class="icon">
      <app-icon name="checkmark_circle_20_regular" class="primary"></app-icon>
    </div>
    <h3 class="text">{{ visibleText | translate }}</h3>
    <mat-progress-bar [value]="preprocessingStatus"></mat-progress-bar>
  </div>
</div>
<app-catalog-footer
*ngIf="!inModal"
  (event)="nextStep($event)"
  [label]="'LABELS.CONTINUE'"
></app-catalog-footer>
