import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select, Store } from '@ngxs/store';
import {
  Observable,
  Subscription,
  throttleTime
} from 'rxjs';
import { CatalogData } from 'src/app/models/catalog.model';
import { CatalogActions } from 'src/app/state/catalog/catalog.actions';
import { CatalogState } from 'src/app/state/catalog/catalog.state';

@Component({
  selector: 'app-upload-catalog',
  templateUrl: './upload-catalog.component.html',
  styleUrls: ['./upload-catalog.component.scss'],
})
export class UploadCatalogComponent implements OnInit, OnDestroy {
  @Select(CatalogState.catalogData) catalogData$: Observable<CatalogData>;
  @ViewSelectSnapshot(CatalogState.csvSettingsOptions) csvSettingsOptions: any;
  form: UntypedFormGroup;
  links = [
    { text: 'UPLOAD_CATALOG.LINKS.CSV_GUIDE', url: '	https://docs.fileformat.com/spreadsheet/csv/' },
    { text: 'UPLOAD_CATALOG.LINKS.LIBRE_GUIDE', url: 'https://help.libreoffice.org/7.3/en-GB/text/scalc/guide/csv_files.html?&DbPAR=SHARED&System=MAC' },
    { text: 'UPLOAD_CATALOG.LINKS.MICROSOFT_GUIDE', url: 'https://support.microsoft.com/en-us/office/import-or-export-text-txt-or-csv-files-5250ac4c-663c-47ce-937b-339e391393ba#:~:text=Go%20to%20File%20%3E%20Save%20As,or%20CSV%20(Comma%20delimited).' },
    { text: 'UPLOAD_CATALOG.LINKS.GOOGLE_SHEET_GUIDE', url: '	https://www.solveyourtech.com/save-csv-google-sheets/' },
  ];
  private subscriptions = new Subscription();
  valueChangingByBackgroundProcess = false;
  constructor(public fb: UntypedFormBuilder, public store: Store) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      textSeparator: '"',
      charset: 'windows',
      csvSeparator: ';',
      isClosed: null,
    });

    this.subscriptions.add(
      this.catalogData$.subscribe((catalogData) => {
        if (catalogData) {
          this.valueChangingByBackgroundProcess = true;
          this.form.patchValue(catalogData.setup);
          this.valueChangingByBackgroundProcess = false;
        }
      }));

    this.subscriptions.add(
      this.form.valueChanges.pipe(
        throttleTime(500)
      ).subscribe(() => {
        if(!this.valueChangingByBackgroundProcess){
          this.saveSettings();
        }
      }));
  }

  saveSettings() {
    this.store.dispatch(new CatalogActions.SetCsvSettings(this.form.value));
  }

  nextStep(nextStep: number) {
    this.store.dispatch(
      new CatalogActions.GoToStep(nextStep)
    );
  }

  ngOnDestroy(): void {}
}
