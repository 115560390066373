<div class="panda-row-errors--data">
  <div class="container" *ngIf="canDisplayTable()">
    <h2>{{ "STATUS_REPORT.PANDA_ROW_ERRORS.LABELS.ERROR_TABLE.TITLE" | translate }}</h2>
    <div class="scroll-table">
      <table class="error-table" >
        <thead>
          <tr>
            <th *ngFor="let header of headersKeys">
              <span>{{pandaRowErrorsData?.headers[header]}}</span>
              <br>
              <span>{{header}}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of pandaRowErrorsData?.pandaRowErrors" >
            <td
              *ngFor="let header of headersKeys;"
              [ngClass]="{'pandaRowError': isFieldInError(row,header)}">
              <span *ngIf="header=='row_id'" > {{getFieldValue(header,row)}}</span>
              <textarea
                *ngIf="header!='row_id'"
                value="{{getFieldValue(header,row)}}"
                (change)="inputChanged(header,row,inputField.value)"
                #inputField></textarea>
              <span *ngIf="isFieldInError(row,header)"  class="tooltip">{{row[header].errors}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-navigation">
      <button
        [hidden]="currentPageNumber == 1"
        id="pageNavigator_back_full"
        class="table-navigator"
        (click)="getPandaRowErrors(1)"
      >
        {{"<<"}}
      </button>
      <button
        [hidden]="currentPageNumber == 1"
        id="pageNavigator_back"
        class="table-navigator"
        (click)="getPandaRowErrors(currentPageNumber - 1)"
      >
        {{"<"}}
      </button>
      <button *ngFor="let pageNumber of pages"
              [ngClass]="{'currentPage': isCurrentPage(pageNumber)}"
              id="pageNavigator{{pageNumber}}"
              (click)="getPandaRowErrors(pageNumber)"
      >{{pageNumber}}</button>
      <button
        [hidden]="currentPageNumber == pages.length"
        id="pageNavigator_forward"
        class="table-navigator"
        (click)="getPandaRowErrors(currentPageNumber + 1)"
      >
        {{">"}}
      </button>
      <button
        [hidden]="currentPageNumber == pages.length"
        id="pageNavigator_forward_full"
        class="table-navigator"
        (click)="getPandaRowErrors(pages.length)"
      >{{">>"}}</button>
    </div>
    <div class="update-db-btn">
      <app-button
        [disabled]="pandaRowsToUpdate.length<=0"
        id="status-report-update-db-values-btn"
        class="update-db-btn"
        text="STATUS_REPORT.PANDA_ROW_ERRORS.LABELS.UPDATE_BTN"
        (click)="updatePandaRowsValues()"
      ></app-button>
    </div>
  </div>
</div>
