import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Item } from 'src/app/models/item.model';
import { CatalogState } from 'src/app/state/catalog/catalog.state';

@Component({
  selector: 'app-import-settings',
  templateUrl: './import-settings.component.html',
  styleUrls: ['./import-settings.component.scss'],
})
export class ImportSettingsComponent implements OnInit {
  @Input() label: string;
  @Input() settingType: string;
  @Input() options: Item[];
  @Input() control: AbstractControl;
  @Input() helpMessage: string;

  get formControl() {
    return this.control as UntypedFormControl;
  }

  constructor() {}

  ngOnInit(): void {}
}
