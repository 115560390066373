import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-mappings-modal',
  templateUrl: './confirm-mappings-modal.component.html',
  styleUrls: ['./confirm-mappings-modal.component.scss'],
})
export class ConfirmMappingsModalComponent{
  emptyColumns: any[];
  alreadyMappedColumns: any[];
  multipleOptMappings: any[];
  disableNext: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmMappingsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any) {
    this.emptyColumns = this.data.emptyColumns;
    this.alreadyMappedColumns = this.data.alreadyMappedColumns;
    this.multipleOptMappings = this.data.multipleOptMappings;
    this.disableNext = this.data.disableNext;
  }

  closeModal() {
    this.dialogRef.close("close");
  }

  nextStep() {
    if (!this.disableNext) {
      this.dialogRef.close("save");
    }
  }
}
