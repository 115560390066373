<div class="confirm-modal">

  <span *ngIf="alreadyMappedColumns.length > 0" >
    <h3>
      {{
      "COLUMNS_MAPPINGS.CONFIRM_MODAL.TITLE_ALREADY_MAPPED"
        | translate: { number: alreadyMappedColumns.length }
      }}
    </h3>
    <div class="sub-text">
      {{ "COLUMNS_MAPPINGS.CONFIRM_MODAL.SUB_TITLE_ALREADY_MAPPED" | translate }}
    </div>
    <div id="map_confirm_modal_column_list_already_mapped" class="column-list">
    <div class="column invalid" *ngFor="let column of alreadyMappedColumns">
      <app-icon name="info_20_filled" class="grey"></app-icon>
      <h4 class="name">{{ column  }}</h4>
    </div>
   </div>
  </span>

  <span *ngIf="multipleOptMappings.length > 0" >
    <h3>
      {{
      "COLUMNS_MAPPINGS.CONFIRM_MODAL.TITLE_MULTIPLE_MAPPED"
        | translate: { number: multipleOptMappings.length }
      }}
    </h3>
    <div class="sub-text">
      {{ "COLUMNS_MAPPINGS.CONFIRM_MODAL.SUB_TITLE_MULTIPLE_MAPPED" | translate }}
    </div>
    <div id="map_confirm_modal_column_list_multiple_mapped" class="column-list">
      <div class="column invalid" *ngFor="let column of multipleOptMappings">
        <app-icon name="info_20_filled" class="grey"></app-icon>
        <h4 class="name">{{ column  }}</h4>
      </div>
   </div>
  </span>


  <span *ngIf="emptyColumns.length > 0" >
  <h3>
    {{
      "COLUMNS_MAPPINGS.CONFIRM_MODAL.TITLE"
        | translate: { number: emptyColumns.length }
    }}
  </h3>
  <div class="sub-text">
    {{ "COLUMNS_MAPPINGS.CONFIRM_MODAL.SUB_TITLE" | translate }}
  </div>
  <div class="column-list" id="map_confirm_modal_column_list_not_mapped">
    <div class="column" *ngFor="let column of emptyColumns">
      <app-icon name="info_20_filled" class="grey"></app-icon>
      <h4 class="name">{{ column | mappingName }}</h4>
    </div>
  </div>
  <div class="information">
    {{ "COLUMNS_MAPPINGS.CONFIRM_MODAL.INFORMATION" | translate }}
  </div>
  </span>
  <div class="buttons">
    <div class="button-container">
      <app-button
        id="map_confirm_modal_btn_close"
        (click)="closeModal()"
        type="stroked"
        text="COLUMNS_MAPPINGS.CONFIRM_MODAL.ASSIGN_COLUMNS"
      ></app-button>
    </div>
    <div class="button-container">
      <app-button
        id="map_confirm_modal_btn_continue"
        [disabled]="disableNext"
        (click)="nextStep()"
        color="warn"
        text="COLUMNS_MAPPINGS.CONFIRM_MODAL.CONTINUE_ANYWAY"
      ></app-button>
    </div>
  </div>
</div>
