<!--This component is deprecated since we can use mandatory_columns component in recommemded mode -->
<h2 class="mandatory-columns--title">{{ "LABELS.RECOMMENDED" | translate }}</h2>
  <div class="column column--title">
    <div class="name name--title"> {{ "COLUMNS_MAPPINGS.LABELS.TRADEBYTE_ATTRIBUTE" | translate }}</div>
    <div class="select select--title">  {{ "COLUMNS_MAPPINGS.LABELS.COLUMN_NAME" | translate }}</div>
  </div>
  <div class="column mandatory-column" *ngFor="let column of recommendedColumns; let i = index">
    <div class="name">
      <div  *ngIf="column.arrayIndex == 0 || column.arrayIndex == -1" >{{ column | mappingName }}</div>
      <div class="pandaColumnName">{{ column.displayColumn }}</div>
    </div>

    <div class="select">
      <mat-select [(value)]="column.option">
        <mat-option [value]="-1" [disabled]="true">{{ "COLUMNS_MAPPINGS.LABELS.SELECT_OPTION" | translate }}</mat-option>
        <mat-option *ngFor="let option of columnNames" [value]="option">{{ option }}</mat-option>
      </mat-select>
      <div class="add-array-item-button" (click)="addMapping(column, i)" *ngIf="shouldDisplayAdd(column)">
        <button mat-stroked-button ><app-icon name="add_24_filled" class="primary"></app-icon></button>
      </div>
      <div class="remove-array-item-button" (click)="deleteMapping(column, i)" *ngIf="shouldDisplayDelete(column)">
        <button mat-stroked-button ><app-icon name="delete_24_regular" class="primary"></app-icon></button>
      </div>
    </div>
  </div>
