<div class="confirm-modal">
  <h3>
    {{ "CONFIRM_CHANGES_MODAL.TITLE" | translate }}
  </h3>
  <div class="sub-text">
    {{ "CONFIRM_CHANGES_MODAL.REFRESH_TEXT" | translate }}
  </div>
  <div class="buttons">
    <div class="button-container">
      <app-button
        id="com_modal_btn_refresh_page"
        (click)="closeModal()"
        type="stroked"
        text="CONFIRM_CHANGES_MODAL.REFRESH_PAGE"
      ></app-button>
    </div>
  </div>
</div>
