import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { CatalogActions } from 'src/app/state/catalog/catalog.actions';
import { CatalogState } from 'src/app/state/catalog/catalog.state';

@Component({
  selector: 'app-preprocessing-progress',
  templateUrl: './preprocessing-progress.component.html',
  styleUrls: ['./preprocessing-progress.component.scss'],
})
export class PreprocessingProgressComponent implements OnInit, OnDestroy {
  @ViewSelectSnapshot(CatalogState.preprocessingStatus)
  preprocessingStatus: number;
  checkForProgress: any;
  preprocessingMilestones: string[] = [
    'PREPROCESSING.UPLOADING',
    'PREPROCESSING.CHECKING',
    'PREPROCESSING.ANALYZING',
    'PREPROCESSING.CATEGORIES_MAPPING',
    'PREPROCESSING.CHECKING_COMPLETED',
  ];
  visibleText: string;
  inModal = false;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: { inModal: boolean }
  ) {}

  ngOnInit(): void {
    this.inModal = this.data?.inModal || false;
    this.visibleText = this.preprocessingMilestones[1];
    this.checkForProgress = setInterval(() => {
      if (this.preprocessingStatus === 100) {
        clearInterval(this.checkForProgress);
        setTimeout(() => {
          this.dialog.closeAll();
        }, 1000);
        this.visibleText =
          this.preprocessingMilestones[this.preprocessingMilestones.length - 1];
      } else {
        this.store.dispatch(new CatalogActions.UpdatePreprocessingStatus());
        this.visibleText =
          this.preprocessingMilestones[
            Math.ceil(this.preprocessingStatus / 20)
          ];
        // close pop-up after file upload errored.
        if (!this.visibleText) {
          this.visibleText = "Error";
          setTimeout(() => {
            this.dialog.closeAll();
          }, 500);
        }
      }
    }, 500);
  }

  nextStep(nextStep: number) {
    this.dialog.closeAll();
    this.store.dispatch(new CatalogActions.GoToStep(nextStep));
  }

  ngOnDestroy(): void {
    clearInterval(this.checkForProgress);
  }
}
