<div class="container">
  <h1>{{"COLUMNS_MAPPINGS.TITLE" | translate }}</h1>
  <div class="sub-title">
    {{"COLUMNS_MAPPINGS.SUB-TEXT" | translate }}
  </div>

  <div class="menu">
    <h3 id="col_map_menu_btn_{{column.id}}"
      class="type"
      *ngFor="let column of columns"
      (click)="goToColumn(column.id)"
    >
      {{ column.name | translate }}
    </h3>
  </div>
  <div class="content">

    <div class="columns">
      <div class="mandatory-columns column" #mandatory>
        <app-mandatory-columns
          [mandatoryColumns]="mandatoryData"
          [columnNames]="columnNames"
          [popupOpened]="popupOpened"
          [userLang]="userLang[0]"
          [topArrayMappings]="topArrayMappings"
          [isInRecommendedMode]="false"
        ></app-mandatory-columns>
        <hr />
      </div>
      <div class="recommended-columns column" #recommended>
        <app-mandatory-columns
          [id]="columns[1].id"
          [mandatoryColumns]="recommendedData"
          [columnNames]="columnNames"
          [popupOpened]="false"
          [userLang]="userLang[0]"
          [topArrayMappings]="topArrayMappings"
          [isInRecommendedMode]="true"
        ></app-mandatory-columns>
        <hr />
      </div>
      <div class="optional-mappings column" #optional>
        <app-optional-mappings
          [columnNames]="columnNames"
          [optionalMappings]="optionalMappings"
          [optionalMappingErrors]="optionalMappingErrors" >
        </app-optional-mappings>
        <hr />
      </div>
    </div>
  </div>
</div>
<app-catalog-footer (event)="nextStep($event)"></app-catalog-footer>
