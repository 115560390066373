import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import { plainToClass } from 'class-transformer';
import {finalize, catchError, EMPTY, tap, take, map} from 'rxjs';
import { CatalogData, UploadSetup } from 'src/app/models/catalog.model';
import { Node, NodeStatus, StatusBar } from 'src/app/models/status-bar.model';
import {
  AuthHelperService,
  SaveOptions,
} from 'src/app/services/auth-helper.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { NavigationActions } from '../navigation/navigation.actions';
import { CatalogActions } from './catalog.actions';
import {PandaTemplateService} from "@app/services/panda-template.service";
import {FileSaverService} from "ngx-filesaver";
import {Retailer} from "@app/models/retailer.model";

export interface CatalogStateModel {
  catalogData: CatalogData;
  csvSettingOptions: any;
  csvFileUploadProgress: number;
  statusBar: StatusBar;
  preprocessingStatus: number;
}
@State<CatalogStateModel>({
  name: 'catalog',
  defaults: {
    catalogData: new CatalogData({
      setup: { textSeparator: '"', charset: 'windows', csvSeparator: ';' },
    }),
    csvSettingOptions: null,
    csvFileUploadProgress: null,
    statusBar: null,
    preprocessingStatus: 0,
  },
})

@Injectable()
export class CatalogState {
  startPageUrl = '/get-started';

  constructor(
    private catalogService: CatalogService,
    private snackBar: MatSnackBar,
    private authHelp: AuthHelperService,
    private router: Router,
    private zone: NgZone,
    private store: Store,
    private pandaTemplateService: PandaTemplateService,
    private fileSaver: FileSaverService
  ) {
  }

  @Selector()
  static csvFile(state: CatalogStateModel) {
    return {
      name: state.catalogData.originalFileName,
      size: state.catalogData.originalFileSize,
    };
  }

  @Selector()
  static accountNumber(state: CatalogStateModel) {
    return `${state.catalogData.user.userName} | ${state.catalogData.user.brand.accountName} (${state.catalogData.user.brand.accountNumber})`;
  }

  @Selector()
  static statusBar(state: CatalogStateModel) {
    return state.statusBar;
  }

  @Selector()
  static saveButtonDisabled(state: CatalogStateModel) {
    return state.catalogData.originalFileName === null;
  }

  @Selector()
  static catalogData(state: CatalogStateModel) {
    return state.catalogData;
  }

  @Selector()
  static csvSettingsOptions(state: CatalogStateModel) {
    return state.csvSettingOptions;
  }

  @Selector()
  static csvFileUploadProgress(state: CatalogStateModel) {
    return state.csvFileUploadProgress;
  }

  @Selector()
  static preprocessingStatus(state: CatalogStateModel) {
    return state.preprocessingStatus;
  }

  @Action(CatalogActions.DeleteCSV)
  deleteCSV(ctx: StateContext<CatalogStateModel>) {
    const catalogId = ctx.getState().catalogData.catalogId;
    return this.catalogService
      .deleteCSV(catalogId)
      .subscribe((catalogData: CatalogData) => ctx.patchState({catalogData}));
  }

  @Action(CatalogActions.UploadCSV)
  uploadCSV(
    ctx: StateContext<CatalogStateModel>,
    {file}: CatalogActions.UploadCSV
  ) {
    this.authHelp.saveSettingsHelper(SaveOptions.upload).subscribe((res) => {
      if (res) {
        this.catalogService
          .uploadCatalog(file, ctx.getState().catalogData.catalogId)
          .pipe(
            finalize(() => {
              ctx.patchState({csvFileUploadProgress: 0});
              ctx.dispatch(new CatalogActions.GetCatalogState());
            }),
            catchError((error: HttpErrorResponse) => {
              this.zone.run(() => {
                this.snackBar.open(`${error?.error?.message}`, 'Close');
              });
              return EMPTY;
            })
          )
          .subscribe((event: any) => {
            if (event.type == HttpEventType.UploadProgress) {
              ctx.patchState({
                csvFileUploadProgress: Math.round(
                  100 * (event.loaded / <number>event.total)
                ),
              });
            }
          });
      }
    });
  }

  @Action(CatalogActions.SetCsvSettings)
  saveScvSettings(
    ctx: StateContext<CatalogStateModel>,
    {settings}: CatalogActions.SetCsvSettings
  ) {
    this.authHelp.saveSettingsHelper(SaveOptions.settings).subscribe((res) => {
      if (res) {
        this.catalogService
          .saveCsvSettings(settings)
          .subscribe((setup: UploadSetup) => {
            ctx.patchState({
              catalogData: {
                ...ctx.getState().catalogData,
                setup,
              },
            });
            ctx.dispatch(new CatalogActions.GetCatalogState());
          });
      }
    });
  }

  @Action(CatalogActions.GetCsvSettingsOptions)
  getScvSettingOptions(ctx: StateContext<CatalogStateModel>) {
    ctx.patchState({
      csvSettingOptions: {
        separators: [
          {value: 'semicolon', viewValue: ';', apiValue: ';'},
          {value: 'tab', viewValue: '<<TAB>>', apiValue: '\t'},
          {value: 'pipe', viewValue: '|', apiValue: '|'},
          {value: 'comma', viewValue: ',', apiValue: ','},
        ],
        textSeparators: [
          {value: 'doublequotes', viewValue: '"', apiValue: '"'},
          {value: 'singlequote', viewValue: "'", apiValue: "'"},
          {value: 'tilde', viewValue: '~', apiValue: '~'},
        ],
        charsets: [
          {
            value: 'windows',
            viewValue: 'Western European (Windows-1252/WinLan-1)',
            apiValue: 'windows',
          },
          {value: 'utf', viewValue: 'Unicode (UTF-8)', apiValue: 'utf'},
        ],
      },
    });
  }

  @Action(CatalogActions.GetStatusBar)
  getStatusBar(ctx: StateContext<CatalogStateModel>) {
    return this.catalogService
      .getStatusBar()
      .subscribe((statusBar: StatusBar) => {
        const newStatus = {
          nodes: statusBar.nodes.map((node: Node) => {
            node.status = NodeStatus.NOT_COMPLETED;
            return node;
          }),
        };
        ctx.patchState({statusBar: plainToClass(StatusBar, newStatus)});
        ctx.dispatch(new CatalogActions.GetCatalogState());
      });
  }

  @Action(CatalogActions.UpdateStatusBar)
  updateStatusBar(ctx: StateContext<CatalogStateModel>) {
    const state = ctx.getState();
    const statusBar = state.statusBar;
    let activeNode = state.catalogData?.user?.userState;
    const maxState = state.catalogData.maxState;
    if (activeNode.order > maxState.order) {
      activeNode.order = maxState.order;
      ctx.dispatch(new CatalogActions.GoToStep(maxState.order));
    }
    ctx.dispatch(new NavigationActions.SetCurrentStep(activeNode.order));
    const newStatus = {
      maxState: maxState,
      nodes: statusBar?.nodes.map((n: Node) => {
        const node = {...n};
        if (node.order <= maxState.order) {
          node.status = NodeStatus.COMPLETED;
        } else if (node.order > activeNode.order) {
          node.status = NodeStatus.NOT_COMPLETED;
        } else {
          node.status = NodeStatus.ACTIVE;
          if (
            node.order !== activeNode.order ||
            node.slug !== this.router.url.split('/')[2]
          ) {
            ctx.dispatch(new CatalogActions.GoToStep(node.order));
          }
        }
        return node;
      }),
    };
    ctx.patchState({statusBar: newStatus});
  }

  @Action(CatalogActions.UpdatePreprocessingStatus)
  new(ctx: StateContext<CatalogStateModel>) {
    return this.catalogService
      .uploadProgressBarStatus(ctx.getState().catalogData.catalogId)
      .subscribe((data: any) => {
        ctx.patchState({
          preprocessingStatus:
          data?.processedPercentage,
        });
      });
  }

  @Action(CatalogActions.GetCatalogState)
  getCatalogState(ctx: StateContext<CatalogStateModel>) {
    return this.catalogService
      .getCategoryState()
      .subscribe((catalogData: CatalogData) => {
        if (catalogData !== null) {
          ctx.patchState({catalogData});
          ctx.dispatch(new CatalogActions.UpdateStatusBar());
        } else {
          const catalogData = {
            state: {
              order: 1,
            },
          };
          ctx.dispatch(new CatalogActions.UpdateStatusBar());
        }
      });
  }

  @Action(CatalogActions.GoToStep)
  goToStep(
    ctx: StateContext<CatalogStateModel>,
    {step}: CatalogActions.GoToStep
  ) {
    this.authHelp.getDataHelper().subscribe((res) => {
      if (res) {
        this.catalogService
          .saveCatalogData(step)
          .subscribe((catalogData: CatalogData) => {
            ctx.patchState({catalogData});
            ctx.dispatch([
            new CatalogActions.UpdateStatusBar(),
              new NavigationActions.NavigateToNextStep(
                catalogData.user.userState.slug
              ),
            ]);
          });
      }
    });
  }

  @Action(CatalogActions.DownloadPandaTemplate)
  downloadPandaTemplate() {
    const catalogId = this.store.selectSnapshot(
      CatalogState.catalogData
    ).catalogId;
    return this.pandaTemplateService.downloadPandaTemplateFile(catalogId).pipe(
      tap((res: any) => {
        const name =
          res?.headers?.get('Content-Disposition') ||
          res?.headers?.get('content-disposition');
        this.fileSaver.save(res.body, name?.split('=')[1]);
      })
    );
  }

  @Action(CatalogActions.SetFileType)
  setFileType(
    ctx: StateContext<CatalogStateModel>,
    {fileType}: CatalogActions.SetFileType
  ) {
    ctx.dispatch([
      new CatalogActions.UpdateStatusBar(),
    ]);
    return this.catalogService.setFileType(fileType);
  }

  @Action(CatalogActions.SetCatalogRetailers)
  setCatalogRetailers(
    ctx: StateContext<CatalogStateModel>,
    {ids}: CatalogActions.SetCatalogRetailers
  ) {
    return this.catalogService.setCatalogRetailers(ids);
  }

  @Action(CatalogActions.GetCatalogRetailers)
  getCatalogRetailers(ctx: StateContext<CatalogStateModel>) {
    return this.catalogService.getCatalogRetailers().pipe(
      map((response: Retailer[]) => {
          return plainToClass(Retailer, response)
        }
      )
    );
  }

  @Action(CatalogActions.ResetCatalog)
  resetCatalog(ctx: StateContext<CatalogStateModel>,  {resetModelComponent}: CatalogActions.ResetCatalog) {
    this.catalogService.resetCatalog()
      .subscribe( () =>{
        resetModelComponent.closeModal();
        this.catalogService
        .saveCatalogData(1)
        .subscribe((catalogData: CatalogData) => {
          ctx.patchState({ catalogData });
          ctx.dispatch([
            new CatalogActions.UpdateStatusBar(),
            new NavigationActions.NavigateToNextStep(
              catalogData.user.userState.slug
            ),
          ]);
          if(window.location.pathname.toString() == this.startPageUrl){
            location.reload();
          } else {
            ctx.dispatch([
              new NavigationActions.NavigateToNextStep(
                catalogData.user.userState.slug
              ),
            ]);
          }}
        );
      }
    );
  };
}
