<h1>{{ "STATUS_REPORT.TITLE" | translate }}</h1>
<div class="status-report--instruction">
  <div class="icon">
    <app-icon name="info_24_filled" class="primary"></app-icon>
  </div>
  <div class="text-col">
    <h3 class="sub-title">
      {{ "STATUS_REPORT.INSTRUCTIONS.TITLE" | translate }}
    </h3>
    <div class="text"></div>
  </div>
</div>

<div class="download-button">
  <app-button
    id="stat_rep_btn_download_report"
    text="STATUS_REPORT.DOWNLOAD_REPORT"
    (click)="downloadStatusReport()"
  ></app-button>
</div>

<app-panda-row-errors (changedRowsEvent)="updatePandaRowsErrors($event)"></app-panda-row-errors>

<app-catalog-footer
  (event)="nextStep($event)"
  label="LABELS.CONTINUE"
></app-catalog-footer>
