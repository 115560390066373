import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { plainToClass } from "class-transformer";
import { map } from "rxjs";
import { environment } from "src/environments/environment";
import { Auth } from "../models/auth.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiUrl + '/authenticate'

  constructor(private http: HttpClient, private store: Store) {
  }

  login(jwt: string) {
    let httpRequestOptions = {}

    if(!environment.disableCsrf) {

      httpRequestOptions = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': environment.allowedOrigin,
      }
    }

    }

    return this.http.post<Response>(`${this.baseUrl}/login`, {
      jwt
    }, httpRequestOptions
    ).pipe(map((response: Response) => plainToClass(Auth, response)))
  }
}
