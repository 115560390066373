import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { CatalogData } from 'src/app/models/catalog.model';
import { CatalogState } from 'src/app/state/catalog/catalog.state';

@Component({
  selector: 'app-catalog-footer',
  templateUrl: './catalog-footer.component.html',
  styleUrls: ['./catalog-footer.component.scss'],
})
export class CatalogFooterComponent {
  @ViewSelectSnapshot(CatalogState.saveButtonDisabled)
  saveButtonDisabled: boolean;
  @ViewSelectSnapshot(CatalogState.catalogData)
  catalogData: CatalogData;
  @Input() label = 'LABELS.SAVE_AND_CONTINUE';
  @Output() event: EventEmitter<number> = new EventEmitter();

  constructor(private store: Store) {}

  nextStep() {
    if (!this.saveButtonDisabled) {
      this.event.emit(this.catalogData.user.userState.order + 1);
    }
  }
}
