import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ViewSelectSnapshot} from "@ngxs-labs/select-snapshot";
import {StatusReportState} from "@app/state/status-report/status-report.state";
import {PandaRowErrorsData} from "@app/models/pandaRowErrorsData";
import {Select, Store} from "@ngxs/store";
import {StatusReportActions} from "@app/state/status-report/status-report.actions";
import {PandaRowUpdateData} from "@app/models/pandaRowUpdateData";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {
  UpdatesNotSavedModalComponent
} from "@app/pages/category/status-report/updates-not-saved-modal/updates-not-saved-modal.component";

@Component({
  selector: 'app-panda-row-errors',
  templateUrl: './panda-row-errors.component.html',
  styleUrls: ['./panda-row-errors.component.scss']
})
export class PandaRowErrorsComponent implements OnInit {

  @ViewSelectSnapshot(StatusReportState.pandaRowErrorsData) pandaRowErrorsData: PandaRowErrorsData;
  @Select(StatusReportState.updatePandaRowsStatus) updateStatus: any;
  @Output() changedRowsEvent = new EventEmitter<PandaRowUpdateData[]>();
  ROW_ID_STR: string = 'row_id';
  headersKeys: string[];
  currentPageNumber: number;
  pageSize: number;
  totalPages:number;
  pages: number[];
  pandaRowsToUpdate: PandaRowUpdateData[] = new Array<PandaRowUpdateData>();
  updatingPandaRows: boolean = false;

  constructor(private store: Store, public dialog: MatDialog) {
    this.currentPageNumber = 1;
    this.pageSize = 25;
  }

  ngOnInit(): void {
    this.getPandaRowErrors(this.currentPageNumber);
    this.headersKeys = this.getHeadersArray();
    this.updateStatus.subscribe( (status: any) => {
      if(this.updatingPandaRows){
        this.getPandaRowErrors(1);
        this.updatingPandaRows = false;
      }
    })
  }

  getPandaRowErrors(requestedPageNumber: number){
    if(this.pandaRowsToUpdate.length > 0){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
          pandaRowUpdates: this.pandaRowsToUpdate,
        };
        const dialogRef = this.dialog.open(UpdatesNotSavedModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result === 'continue') {
            this.pandaRowsToUpdate = [];
            this.requestErrorsFromBackend(requestedPageNumber)
          }
        });
    }else{
      this.requestErrorsFromBackend(requestedPageNumber);
    }
    this.totalPages = this.pandaRowErrorsData?.totalPages;
  }

  requestErrorsFromBackend(requestedPageNumber: number){
    this.store.dispatch(new StatusReportActions.GetPandaRowErrorsData(requestedPageNumber,this.pageSize));
    this.currentPageNumber = requestedPageNumber;
    this.totalPages = this.pandaRowErrorsData?.totalPages;
  }

  isFieldInError(row: any, header: string){
    return row[header].errors !=  undefined;
  }

  getHeadersArray(){
    this.getPagesArray(this.pandaRowErrorsData?.totalPages)
    if(this.isHeadersArrayReady()){
      this.headersKeys = Array.from(Object.keys(this.pandaRowErrorsData?.headers));
    }
    return this.headersKeys;
  }

  canDisplayTable(){
    return this.isHeadersArrayReady()?this.getHeadersArray().length > 0:false;
  }

  isHeadersArrayReady(){
    return this.pandaRowErrorsData?.headers != null
    }

  getFieldValue(header: string, row:any){
    let value = "";
    if(row[header].value == "" || row[header] == ""){
      return value;
    }
    return (row[header].value)? row[header].value :row[header];
  }

  isCurrentPage(pageNumber: number){
    return (pageNumber==this.currentPageNumber);
  }

  getPagesArray(totalPages: number){
    this.pages = Array(totalPages).fill(1).map((_,i)=>i+1);
  }

  updatePandaRowsValues(){
    this.updatingPandaRows = true;
    this.store.dispatch(new StatusReportActions.UpdatePandaRowsValues(this.pandaRowsToUpdate))
    this.pandaRowsToUpdate = new Array<PandaRowUpdateData>;
    this.emitChangedRows(this.pandaRowsToUpdate);
  }

  inputChanged(header: string, row: any, newValue: string){
    //check if row already modified
    let newPandaRowData: PandaRowUpdateData;
    let modifiedRowIndex = -1;
    for(let i = 0; i < this.pandaRowsToUpdate.length; i++) {
      // check if row already have edited field
      if (this.pandaRowsToUpdate[i][this.ROW_ID_STR] == row[this.ROW_ID_STR]) {
        modifiedRowIndex = i;
      }
    }
    if(modifiedRowIndex < 0){
      newPandaRowData = {row_id: row.row_id};
    }else{
      newPandaRowData = this.pandaRowsToUpdate[modifiedRowIndex];
      delete this.pandaRowsToUpdate[modifiedRowIndex];
      // clean empty indexes after delete
      this.pandaRowsToUpdate = this.pandaRowsToUpdate.filter(function (element){
        return element != null;})
    }
    // update new value on selected column
    newPandaRowData[header] = newValue;
    this.pandaRowsToUpdate.push(newPandaRowData);

    // send updated array to Status-Report page
    this.emitChangedRows(this.pandaRowsToUpdate);
  }

  emitChangedRows(changedRows: PandaRowUpdateData[]){
    this.changedRowsEvent.emit(changedRows);
  }
}
