import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { httpRequestOptions } from "../../constants";
import { Observable} from "rxjs";
import {PandaRowUpdateData} from "@app/models/pandaRowUpdateData";

@Injectable({
  providedIn: 'root',
})
export class StatusReportService {
  baseUrl = environment.apiUrl + '/catalog';

  constructor(private http: HttpClient) {}

  getStatusReport(catalogId: number) {
    return this.http.get(`${this.baseUrl}/${catalogId}/status-report`, httpRequestOptions);
  }

  downloadStatusReportFile(catalogId: number) {
    return this.http.get(`${this.baseUrl}/${catalogId}/pdf-status-report`, {
      responseType: 'blob',
      observe: 'response',
      withCredentials: !environment.disableCsrf
    });
    // window.open(`${this.baseUrl}/${catalogId}/pdf-status-report`)
  }

  getFieldErrors(pageNumber: number, pageSize: number):Observable<any> {
    return this.http.get(`${this.baseUrl}/fieldErrors?page=${pageNumber}&size=${pageSize}`, httpRequestOptions);
  }

  updatePandaRowValues(newPandaRowsValues: PandaRowUpdateData[]): Observable<any>{
    return this.http.post<PandaRowUpdateData>(environment.apiUrl +`/panda-row`,newPandaRowsValues, httpRequestOptions)
  }
}
