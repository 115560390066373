<mat-toolbar>
  <img src="assets/logo.svg" alt="Logo"
       id="navbar_btn_tb_logo"
       (click)="goToGetStarted()"/>
  <div class="status-bar" *ngIf="authorized">
    <app-status-bar></app-status-bar>
  </div>
  <div>
    <button mat-raised-button
            id="navbar_btn_new_catalog"
            class="reset-menu-button"
            *ngIf="authorized"
            [matMenuTriggerFor]="resetMenu">
      <span>{{"RESET_MENU.RESET_BTN" | translate}}</span>
    </button>
  </div>

  <div class="menu">
    <span>
      <mat-select [formControl]="selected">
        <mat-option
          *ngFor="let lang of languages"
          [value]="lang.value"
          (click)="changeLanguage(lang.value)">
          {{ lang.viewValue }}
        </mat-option>
      </mat-select>
    </span>
  </div>

  <div id="account-button">
      <button mat-icon-button
              id="navbar_btn_menu_acc"
              *ngIf="authorized"
              [matMenuTriggerFor]="userMenu">
        <mat-icon>person</mat-icon>
      </button>
  </div>

</mat-toolbar>
<!--
  User Menu
-->

<mat-menu #userMenu="matMenu">
  <div class="account-number" data-matomo-mask>{{ accountNumber }}</div>
  <!-- <button mat-menu-item>
    <mat-icon>person_outline</mat-icon>
    <span>{{ accountNumber }}</span>
  </button> -->
  <a target="_blank" href='{{"USER_MENU.PRIVACY_POLICY_LINK" | translate}}'>
  <button mat-menu-item id="acc_menu_btn_policy" >
    <mat-icon>info</mat-icon>
    <span>{{"USER_MENU.PRIVACY_POLICY_LABEL" | translate}}</span>
  </button>
  </a>
  <button mat-menu-item
          id="navbar_acc_menu_btn_logout"
          (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>{{"LABELS.LOGOUT" | translate}}</span>
  </button>

</mat-menu>

<!--
 Catalog Reset Menu
-->
<mat-menu #resetMenu="matMenu">
  <div class="reset-info">
    <span class="menu info warning">{{"RESET_MENU.INFO" | translate}}</span>
  </div>
  <div>
    <button mat-menu-item id="navbar_reset_warn_btn_proceed" (click)="openResetPopUp()">
      <mat-icon class="warning">warning</mat-icon>
      <span>{{"RESET_MENU.PROCEED_BTN" | translate}}</span>
    </button>
  </div>
</mat-menu>
