<input
  [disabled]="!!csvFile?.name"
  type="file"
  class="file-input"
  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
  (change)="onFileChange($event)"
  (click)="fileUpload.value = null"
  #fileUpload
/>

<div id="input_drop_file"
  class="file-upload"
  #fileUpload
  appDnd
  (fileDropped)="onFileDropped($event[0])"
>
  <div class="icon">
    <app-icon *ngIf="!csvFile?.name" name="document-add-48_active"></app-icon>
    <app-icon *ngIf="csvFile?.name" name="document-add-48_disable"></app-icon>
  </div>
  <div class="button-container">
    <app-button
      id="btn_upload_catalog"
      (click)="fileUpload.click()"
      text="UPLOAD_CATALOG.BUTTON_LABEL"
      color=""
      type="stroked"
      [disabled]="!!csvFile?.name"
    >
    </app-button>
  </div>
  <div class="file-format">
    {{ "UPLOAD_CATALOG.FILE_FORMAT" | translate }}
  </div>
</div>
<div class="progress-bar" *ngIf="uploadProgress > 0">
  <mat-progress-bar
    mode="determinate"
    [value]="uploadProgress"
  ></mat-progress-bar>
</div>
<div class="file-name-container" *ngIf="csvFile?.name">
  <div class="checkmark">
    <app-icon name="checkmark_circle_24_regular" class="primary"></app-icon>
  </div>
  <span class="name">{{ csvFile?.name }}</span
  ><span class="file-size">{{ csvFile?.size | fileSize }}</span>
  <span class="columns-found file-size">{{
    "UPLOAD_CATALOG.COLUMNS_FOUND"
      | translate: { number: catalogData?.columnsFound }
  }}</span>
  <!-- displaying computed rows without counting 1st row for for headers -->
 <span class="computed-rows file-size">{{
    "UPLOAD_CATALOG.COMPUTED_ROWS"
      | translate: { number: (catalogData?.computedRows)-1}
  }}</span>

  <div class="actions">
    <app-icon
      id="btn_delete_file"
      [matTooltip]="'UPLOAD_CATALOG.DELETE_LABEL' | translate"
      name="delete_20_regular"
      (click)="deleteFile()"
    ></app-icon>
  </div>
</div>
