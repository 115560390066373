import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";


@Injectable()
export class XsrfInterceptor implements HttpInterceptor {

  #headerName = 'X-XSRF-TOKEN';

  constructor(private token: HttpXsrfTokenExtractor) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.token.getToken();

    if (token !== null && !req.headers.has(this.#headerName)) {
        req = req.clone({
        headers: req.headers.set(this.#headerName, token),
          withCredentials: true,
      });
    }
    return next.handle(req);

  }

}
