export namespace NavigationActions {
  export class LoadingStart {
    static readonly type = 'NavigationActions.LoadingStart';
  }
  export class LoadingEnd {
    static readonly type = 'NavigationActions.LoadingEnd';
  }

  export class SetCurrentStep {
    static readonly type = 'NavigationActions.SetCurrentStep';
    constructor(public step: number) {}
  }

  export class NavigateToNextStep{
    static readonly type = 'NavigationActions.NavigateToNextStep';

    constructor(public url: string) {}
  }

  export class NavigateToCurrentStep {
    static readonly type = 'NavigationActions.NavigateToCurrentStep';
  }

  export class AddToLoadingQue {
    static readonly type = 'NavigationActions.AddToLoadingQue'

    constructor(public url: string) {}
  }


  export class RemoveFromLoadingQue {
    static readonly type = 'NavigationActions.RemoveFromLoadingQue'

    constructor(public url: string) {}
  }

  export class  EnterStartPage {
    static readonly type = 'NavigationActions.EnterStartPage'
  }

  export class  LeaveStartPage {
    static readonly type = 'NavigationActions.LeaveStartPage'
  }

  export class SetTemplateFlow {
    static readonly type = 'NavigationActions.SetTemplateFlow'
    constructor(public setTemplateFlow: boolean) {}
  }

}
