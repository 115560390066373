import { Type } from 'class-transformer';

export class Node {
  name: string;
  slug: string;
  status: string;
  order: number;
}

export class StatusBar {
  @Type(() => Node)
  nodes: Node[] = [];
  @Type(() => Node)
  maxState?: Node;
}

export const NodeStatus = {
  COMPLETED: 'completed',
  ACTIVE: 'active',
  NOT_COMPLETED: 'not_completed',
};

export const NodeNames = {
  'start-page': 'Start page',
  'upload-csv': 'Upload catalog',
  'preprocessing-progress': 'Scan and extract',
  'catalog-categories': 'Catalog categories',
  'columns-mapping': 'Column mapping',
  'status-report': 'Status report',
  'send': 'Send to TB.ONE',
};
