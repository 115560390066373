import {Component, Host, Input} from '@angular/core';
import {CategoryActions} from "@app/state/category/category.actions";
import {CatalogCategoriesComponent} from "@app/pages/category/catalog-categories/catalog-categories.component";
import {Store} from "@ngxs/store";

@Component({
  selector: 'app-panda-download-template-footer',
  templateUrl: './panda-download-template-footer.component.html',
  styleUrls: ['./panda-download-template-footer.component.scss']
})
export class PandaDownloadTemplateFooterComponent   {

  constructor(private parentComponent: CatalogCategoriesComponent,  private store: Store ) { }

  downloadTemplate() {
    this.parentComponent.validateCategories();
    if (this.parentComponent.categoriesCorrect) {
      this.store.dispatch([new CategoryActions.SetSelectedCategoriesAndDownloadTemplate()]);
    }
  }

}
