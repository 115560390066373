import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select, Store } from '@ngxs/store';
import {
  debounceTime,
  Observable,
  Subscription,
} from 'rxjs';
import { Category, CategoryGroup } from 'src/app/models/category.model';
import { CategoryActions } from 'src/app/state/category/category.actions';
import { CategoryState } from 'src/app/state/category/category.state';
import {NavigationState} from "@app/state/navigation/navigation.state";

@Component({
  selector: 'app-catalog-categories',
  templateUrl: './catalog-categories.component.html',
  styleUrls: ['./catalog-categories.component.scss'],
})
export class CatalogCategoriesComponent implements OnInit, OnDestroy {
  @ViewSelectSnapshot(CategoryState.categoryGroups)
  categoryGroups: CategoryGroup[];
  @Select(CategoryState.viewedCategory) viewedCategory$: Observable<{
    categories: Category[];
    categoryId: number;
  }>;
  @ViewSelectSnapshot(CategoryState.selectedCategoryCount)
  selectedCount: Record<number, number>;
  sc: Record<number, number>;
  numberOfSelectedCategories: number = 0;
  // For Select All bvytton
  allCategoriesSelected = false;
  allCategoriesIdeterminate = false;
  //Form For categories
  form: UntypedFormGroup = new UntypedFormGroup({});
  // Data placeholder
  viewedCategory: {
    categories: Category[];
    categoryId: number;
  } = {
    categories: [],
    categoryId: 0,
  };
  subscriptions = new Subscription();
  // Check if questions are not null;
  categoriesCorrect = false;
  showErrors = false;
  language = 'en';
  //For Search
  searchBarControl: UntypedFormControl = new UntypedFormControl('');
  searchMode = false;
  @Select(CategoryState.searchResults) searchResults$: Observable<{
    categories: Category[];
  }>;
  searchResults: {
    categories: Category[];
  };

  @ViewSelectSnapshot(NavigationState.templateFlow) templateFlow: boolean;

  get categories() {
    return this.form?.get('selectedCategories') as UntypedFormArray;
  }

  constructor(
    public store: Store,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.addSubscriptions();
  }

  addSubscriptions() {
    this.subscriptions.add(
      this.translateService.onLangChange.subscribe((data) => {
        this.language = data.lang;
      })
    );
    this.subscriptions.add(
      this.searchResults$.subscribe((results) => {
        this.searchResults = results;
        this.createFormGroup(results);
      })
    );
    this.subscriptions.add(
      this.viewedCategory$.subscribe((data) => {
        if (!this.searchMode) {
          this.createFormGroup(data);
          this.viewedCategory = data;
          this.onChange();
        }
      })
    );
    this.subscriptions.add(
      this.searchBarControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe((value) => {
          if (!!value) {
            this.searchMode = true;
            this.store.dispatch(
              new CategoryActions.GetSearchBarCategories(value)
            );
            this.store
              .dispatch(new CategoryActions.ChangeViewedCategoryGroup(-1))
              .subscribe(() => {
                this.onChange();
              });
            this.viewedCategory.categoryId = -1;
          } else {
            this.searchMode = false;
            if (this.viewedCategory.categoryId === -1) {
              this.store.dispatch(
                new CategoryActions.ChangeViewedCategoryGroup(
                  this.categoryGroups[0].id
                )
              );
            }
          }
        })
    );
  }

  createFormGroup(data: { categories: Category[]; categoryId?: number }) {
    this.form = this.fb.group({
      selectedCategories: this.fb.array([]),
      unselectedCategories: this.fb.array([]),
    });
    const categorySelected = new UntypedFormArray([]);
    data?.categories.forEach((category) => {
      categorySelected.push(
        this.fb.group({
          value: category?.selected,
          question: category?.questionAnswer,
        })
      );
    });
    this.form.setControl('categories', categorySelected);
  }

  changeSelectedCategory(categoryId: number) {
    this.searchBarControl.patchValue('');
    this.searchMode = false;
    this.store
      .dispatch(new CategoryActions.ChangeViewedCategoryGroup(categoryId))
      .subscribe(() => {
        this.onChange();
      });
  }

  changeSelectedCategoryValue(category: Category, categoryGroupId?: number) {
    let groupId: number = -1;
    if (!this.searchMode) {
      groupId = this.viewedCategory?.categoryId;
    } else {
      groupId = categoryGroupId;
    }
    this.store.dispatch(
      new CategoryActions.UpdateSelectedCategories(
        {
          ...category,
          selected: !category.selected,
        },
        groupId
      )
    );
    if (this.searchMode) {
      this.store.dispatch(
        new CategoryActions.GetSearchBarCategories(this.searchBarControl.value)
      );
    }
  }

  toogleAll() {
    this.allCategoriesSelected = !this.allCategoriesSelected;
    this.store.dispatch(
      new CategoryActions.ToogleAllCategories(this.allCategoriesSelected)
    );
  }

  onChange() {
    // Update state of select all button
    if (
      this.viewedCategory?.categories.filter((c) => !c.selected)?.length === 0
    ) {
      this.allCategoriesSelected = true;
    } else if (
      this.viewedCategory?.categories.filter((c) => c.selected)?.length === 0
    ) {
      this.allCategoriesIdeterminate = false;
      this.allCategoriesSelected = false;
    } else {
      this.allCategoriesSelected = false;
      this.allCategoriesIdeterminate = true;
    }
  }

  nextStep(nextStep: number) {
    this.validateCategories();
    if (this.categoriesCorrect) {
      this.store.dispatch([new CategoryActions.SetSelectedCategories()]);
    }
  }

  public validateCategories() {
    this.searchBarControl.patchValue('');
    this.searchMode = false;
    this.showErrors = true;

    let categoriesCorrect = true;
    this.categoryGroups.forEach((categoryGroup, categoryGroupId) => {
      let categoryGroupValid = true;
      categoryGroup.categories.forEach((category, i) => {
        if (
          category.selected &&
          category.questionSlug &&
          category.questionAnswer === null
        ) {
          categoryGroupValid = false;
          categoriesCorrect = false;
          this.store.dispatch(
            new CategoryActions.ChangeViewedCategoryGroup(categoryGroup.id)
          );
        }
      });
      this.store.dispatch(
        new CategoryActions.ValidateCategories(
          categoryGroupId,
          categoryGroupValid
        )
      );
    });
    this.categoriesCorrect = categoriesCorrect;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
