import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CatalogData } from 'src/app/models/catalog.model';
import { CatalogActions } from 'src/app/state/catalog/catalog.actions';
import { CatalogState } from 'src/app/state/catalog/catalog.state';
import { PreprocessingProgressComponent } from '../../preprocessing-progress/preprocessing-progress.component';

@Component({
  selector: 'app-upload-csv',
  templateUrl: './upload-csv.component.html',
  styleUrls: ['./upload-csv.component.scss'],
})
export class UploadCsvComponent implements OnInit, OnDestroy {
  @ViewSelectSnapshot(CatalogState.csvFile) csvFile: File;
  @ViewSelectSnapshot(CatalogState.catalogData) catalogData: CatalogData;
  @Select(CatalogState.csvFileUploadProgress)
  uploadProgress$: Observable<number>;
  @ViewChild('fileUpload', { static: false }) uploadRef: ElementRef;
  fileControl: UntypedFormControl;
  uploadProgress: number;
  private subscription = new Subscription();
  constructor(private store: Store, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.fileControl = new UntypedFormControl(this.csvFile);
    this.subscription.add(
      this.uploadProgress$.subscribe((v) => {
        this.uploadProgress = v;
      })
    );
  }

  onFileDropped(event: File) {
    if (!this.csvFile?.name) {
      this.store.dispatch(new CatalogActions.UploadCSV(event));
      this.onUpload();
    }
  }

  onFileChange(event: any) {
    if (!this.csvFile?.name) {
      this.store.dispatch(new CatalogActions.UploadCSV(event.target.files[0]));
      this.onUpload();
    }
  }

  onUpload() {
    this.dialog.open(PreprocessingProgressComponent, {
      width: "80%",
      data: {inModal: true}
    });
  }

  deleteFile() {
    this.store.dispatch(new CatalogActions.DeleteCSV());
    this.uploadRef.nativeElement.value = ''
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe;
  }
}
