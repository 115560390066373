<div class="status-bar">
  <div class="status-bar__node" *ngFor="let node of statusBar?.nodes">
    <app-status-bar-node
      id="stat_bar_btn_navigate_to_{{node.slug}}"
      *ngIf=" templateFlow==false"
      (click)="navigateToStep(node)"
      [node]="node"
    ></app-status-bar-node>
  </div>
</div>
