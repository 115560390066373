import {
  HttpClient
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CategoryGroup, SelectedCategory } from '../models/category.model';
import { OptionalMapping } from '../models/optional-mappings';
import {CatalogMapping} from "@app/models/catalog-mappings";
import {httpRequestOptions} from "../../constants";

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  baseUrl = environment.apiUrl + '/category';
  mappingsUrl = environment.apiUrl + '/mapping';

  constructor(private http: HttpClient) {}

  getCategoryGroups() {
    return this.http.get<CategoryGroup[]>(`${this.baseUrl}/`);
  }

  getSelectedCategories() {
    return this.http.get<SelectedCategory[]>(
      `${this.baseUrl}/selected-categories`
    );
  }

  setSelectedCategories(selectedCategories: SelectedCategory[]) {
    return this.http.post(
      `${this.baseUrl}/selected-categories`,
      selectedCategories,
      httpRequestOptions
    );
  }

  getMandatoryAttributes(catalogId: number) {
    return this.http.get<any[]>(
      `${this.mappingsUrl}/mandatory-attributes/catalog/${catalogId}`
    );
  }

  getRecomendedAttributes(catalogId: number) {
    return this.http.get<any[]>(
      `${this.mappingsUrl}/recommended-attributes/catalog/${catalogId}`
    );
  }

  getOptionalMappings(catalogId: number) {
    return this.http.get<any[]>(
      `${this.mappingsUrl}/optional-mappings/catalog/${catalogId}`
    );
  }

  setOptionalMappings(optionalMappings: OptionalMapping[]) {
    return this.http.post(
      `${this.mappingsUrl}/optional-mappings`,
      optionalMappings,
      httpRequestOptions
    );
  }

  getRecommendedMappings(catalogId: number) {
    return this.http.get<any[]>(
      `${this.mappingsUrl}/recommendations/catalog/${catalogId}`
    );
  }

  getCatalogMappings(catalogId: number) {
    return this.http.get<CatalogMapping>(
      `${this.mappingsUrl}/catalog-mappings/catalog/${catalogId}`
    );
  }

  getGeneralMappings(catalogId: number) {
    return this.http.get<any[]>(
      `${this.mappingsUrl}/general-mappings/catalog/${catalogId}`
    );
  }

  getColumnNames(catalogId: number) {
    return this.http.get<any[]>(
      `${this.mappingsUrl}/column-names/catalog/${catalogId}`
    );
  }

  setGeneralMappings(generalMappingsData: any[]) {
    return this.http.post(
      `${this.mappingsUrl}/general-mappings`,
      generalMappingsData,
      httpRequestOptions
    );
  }

  setCatalogMappings(catalogMapping: CatalogMapping) {
    return this.http.post(
      `${this.mappingsUrl}/catalog-mappings`,
      catalogMapping,
      httpRequestOptions
    );
  }

  deleteOptionalMapping(columnName: string,catalogId: number) {

    // Required for column names that include brackets
    const encodedColumnName:string  = encodeURIComponent(columnName);

    return this.http.delete(
      `${this.mappingsUrl}/optional-mapping/catalog/${catalogId}/column/${encodedColumnName}`
      ,
      httpRequestOptions
    )
  }
}
