import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-refresh-modal',
  templateUrl: './refresh-modal.component.html',
  styleUrls: ['./refresh-modal.component.scss']
})
export class RefreshModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RefreshModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close('close');
    this.reloadPage();
  }

  // Needed so you can unit test
  reloadPage() {
    window.location.reload();
  }

  nextStep() {
    this.dialogRef.close('continue');
  }
}
