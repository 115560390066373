<ng-template [ngIf]="type === 'raised'">
  <button mat-raised-button [color]="color" [disabled]="disabled">
    {{ text | translate }}
  </button>
</ng-template>
<ng-template [ngIf]="type === 'stroked'">
  <button mat-stroked-button [color]="color" [disabled]="disabled">
    {{ text | translate }}
  </button>
</ng-template>
