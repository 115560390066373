import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder} from "@angular/forms";
import {Router} from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import {CatalogActions} from "@app/state/catalog/catalog.actions";
import {Store} from "@ngxs/store";
import {Retailer} from "@app/models/retailer.model";
import {CatalogService} from "@app/services/catalog.service";
import {CatalogData} from "@app/models/catalog.model";

@Component({
  selector: 'app-get-started-options',
  templateUrl: './get-started-options.component.html',
  styleUrls: ['./get-started-options.component.scss']
})
export class GetStartedOptionsComponent implements OnInit {
  catalogData: CatalogData;
  catalogRetailers: number[] = [];
  displayFileOptions: boolean;
  displayChannelSliders: boolean;
  productCatalogType: string;
  retailers: Retailer[] = [];
  saveButtonDisabled: boolean;
  selectedUniversalCatalog: boolean;
  selectedChannelCatalog: boolean;
  getStartedOptionsForm = this.fb.group({
    ChosenOption: [],
  })
  @Output() event: EventEmitter<number> = new EventEmitter();

  CREATE_AND_DOWNLOAD_OPTION = "CreateAndDownload";
  DOWNLOAD_TEMPLATE = 'download-template';
  CATALOG_TYPE_BASIC = "BASIC";
  CATALOG_TYPE_CHANNEL_SPECIFIC = "CHANNEL";
  BASE_CATALOG_API_URL = '/catalog';
  language = 'en';

  constructor(private fb: UntypedFormBuilder, private router: Router, private translateService: TranslateService, public store: Store, private catalogService: CatalogService) {
    // setting user.catalogStateId to 1 - get-started
    this.catalogService.saveCatalogData(1).subscribe(catalogData => {
        this.catalogData = catalogData;
        this.productCatalogType = catalogData.catalogType;

        // setting visibility of options tabs and retailer selection.
      if(this.catalogData.catalogType == this.CATALOG_TYPE_CHANNEL_SPECIFIC){
        this.displayChannelSliders = true;
        this.displayFileOptions = false;
        this.displayFileOptions = this.catalogRetailers.length > 0;
        this.displayCatalogInfo(2);
      }else{
        this.displayCatalogInfo(1);
        this.displayChannelSliders = false;
        this.displayFileOptions = true;
      }
    });

    // updating retailer selection sliders
    this.catalogService.getCatalogRetailers().subscribe((data: Retailer[])=>{
      this.retailers = data;
      for(let retailer of this.retailers){
        if(retailer.selected){
          this.catalogRetailers.push(retailer.id);
        }
      }
    });
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((data) => {
        this.language = data.lang;
      }
    );
  }

  nextOptionStep(optionStep: string){
    this.productCatalogType = optionStep;
    if(optionStep==this.CATALOG_TYPE_BASIC){
      this.displayChannelSliders = false;
      this.displayFileOptions = true;
      this.catalogRetailers = [];
    } else if(optionStep==this.CATALOG_TYPE_CHANNEL_SPECIFIC){
      this.displayChannelSliders = true;
      this.displayFileOptions = false;
      this.displayFileOptions = this.catalogRetailers.length > 0;
    }
    this.store.dispatch( new CatalogActions.SetCatalogRetailers(this.catalogRetailers));
  }

  displayCatalogInfo(infoIndex: number){
    if(infoIndex == 1){
      this.selectedUniversalCatalog = true;
    } else if(infoIndex == 2){
      this.selectedUniversalCatalog = false;
    }
    this.selectedChannelCatalog = !this.selectedUniversalCatalog;
  }

  onChange(retailerID: number, isChecked: boolean){
    if(isChecked) {
      this.catalogRetailers.push(retailerID);
    } else {
      let index = this.catalogRetailers.indexOf(retailerID);
      this.catalogRetailers.splice(index,1);
    }
    this.displayFileOptions = this.catalogRetailers.length > 0;
    this.store.dispatch( new CatalogActions.SetCatalogRetailers(this.catalogRetailers));
  }

  nextStep(option: String): void{
    this.store.dispatch( new CatalogActions.SetCatalogRetailers(this.catalogRetailers)).subscribe(() =>{
      if(option == this.CREATE_AND_DOWNLOAD_OPTION){
        // Possibly change this in future to define a step if flow is enhanced?
        this.router.navigate([this.BASE_CATALOG_API_URL, this.DOWNLOAD_TEMPLATE]);
      }else{
        // all upload buttons flow depend on option they carry: PANDA, SHOPIFY, CUSTOM.
        this.store.dispatch(
          new CatalogActions.SetFileType(option),
        );
        this.event.emit(this.catalogData.user.userState.order + 1);
      }
    });
  }

  getSliderValue(retailerId: number){
    return this.catalogRetailers.includes(retailerId);
  }
}
