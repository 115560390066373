import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import {GetStartedComponent} from "@app/pages/get-started/get-started.component";
import {EnterStartPageResolver} from "@app/resolvers/enter-start-page.resolver";
import {CatalogDataResolver} from "@app/resolvers/catalog-data.resolver";
import {CategoryModule} from "@app/pages/category/category.module";

const routes: Routes = [
  {
    path: 'get-started',
    canActivate: [AuthGuard],
    component: GetStartedComponent,
    resolve: {
      CatalogDataResolver,
      EnterStartPageResolver
    }
  },
  {
    path: 'catalog',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/category/category.module').then((m) => m.CategoryModule),
  },
  {
    path: 'redirect',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'get-started',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
