import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CatalogDataResolver } from 'src/app/resolvers/catalog-data.resolver';
import { CategoryResolver } from 'src/app/resolvers/category.resolver';
import { CsvSettingsResolver } from 'src/app/resolvers/csv-settings-resolver';
import { StatusReportResolver } from 'src/app/resolvers/status-report.resolver';
import { CatalogCategoriesComponent } from './catalog-categories/catalog-categories.component';
import { CategoryContainerComponent } from './category-container/category-container.component';
import { ColumnsMappingComponent } from './columns-mapping/columns-mapping.component';
import { PreprocessingProgressComponent } from './preprocessing-progress/preprocessing-progress.component';
import { SendToTbOneComponent } from './send-to-tb-one/send-to-tb-one.component';
import { StatusReportComponent } from './status-report/status-report.component';
import { UploadCatalogComponent } from './upload-catalog/upload-catalog.component';
import {PandaTemplateFlowResolver} from "@app/resolvers/panda-template-flow-resolver.service";

const routes: Routes = [
  {
    path: '',
    component: CategoryContainerComponent,
    resolve: {
      catalogData: CatalogDataResolver,
    },
    children: [
      {
        path: 'upload-csv',
        component: UploadCatalogComponent,
        resolve: {
          csvSettings: CsvSettingsResolver,
        },
      },
      {
        path: 'preprocessing-progress',
        component: PreprocessingProgressComponent,
      },
      {
        path: 'catalog-categories',
        component: CatalogCategoriesComponent,
        resolve: {
          categories: CategoryResolver,
        },
      },
      {
        path: 'download-template',
        component: CatalogCategoriesComponent,
        resolve: {
          categories: CategoryResolver,
          TemplateFlowResolver: PandaTemplateFlowResolver
        },
      },
      {
        path: 'columns-mapping',
        component: ColumnsMappingComponent,
        // resolve: {
        //   categories: CategoryMappingsResolver,
        // },
      },
      {
        path: 'status-report',
        component: StatusReportComponent,
        resolve: {statusreport: StatusReportResolver},
      },
      {
        path: 'send',
        component: SendToTbOneComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CategoryRoutingModule {}
