import { Component, OnInit } from '@angular/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import * as FileSaver from 'file-saver';
import { FileSaverService } from 'ngx-filesaver';
import { CatalogData } from 'src/app/models/catalog.model';
import { ExportTbOneService } from 'src/app/services/export-tb-one.service';
import { CatalogState } from 'src/app/state/catalog/catalog.state';

@Component({
  selector: 'app-send-to-tb-one',
  templateUrl: './send-to-tb-one.component.html',
  styleUrls: ['./send-to-tb-one.component.scss'],
})
export class SendToTbOneComponent implements OnInit {
  @ViewSelectSnapshot(CatalogState.catalogData) catalogData: CatalogData;

  constructor(
    private exportService: ExportTbOneService,
    private fileSaver: FileSaverService
  ) {}

  ngOnInit(): void {}

  getFile() {
    this.exportService
      .getPanda(this.catalogData.catalogId)
      .subscribe((res: any) => {
        const name =
        res?.headers?.get('Content-Disposition') ||
        res?.headers?.get('content-disposition');
        this.fileSaver.save(
          res.body,
          name?.split('=')[1]
        );
      });
  }
}
