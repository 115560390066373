import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { CatalogData } from '../models/catalog.model';
import { StatusBar } from '../models/status-bar.model';
import { CatalogActions } from '../state/catalog/catalog.actions';
import { CatalogState } from '../state/catalog/catalog.state';


@Injectable({
  providedIn: 'root',
})
export class CsvSettingsResolver implements Resolve<any> {
  @SelectSnapshot(CatalogState.catalogData) catalogData: Observable<any>;
  constructor(private store: Store) {}
  resolve(): Observable<any> {
    return this.store.dispatch(new CatalogActions.GetCsvSettingsOptions());
  }
}
