import { Component, Input } from '@angular/core';
import { Node, NodeStatus } from 'src/app/models/status-bar.model';

@Component({
  selector: 'app-status-bar-node',
  templateUrl: './status-bar-node.component.html',
  styleUrls: ['./status-bar-node.component.scss']
})
export class StatusBarNodeComponent {
  @Input() node: Node;
  nodeStatusOptions = NodeStatus;
  names: any = {
    'start-page': 'STATUS_BAR.START_PAGE',
    'upload-csv': 'STATUS_BAR.FILE_UPLOAD',
    'preprocessing-progress': 'TO DELETE',
    'catalog-categories': 'STATUS_BAR.CATALOG_CATEGORIES',
    'columns-mapping': 'STATUS_BAR.COLUMN_MAPPING',
    'status-report': 'STATUS_BAR.STATUS_REPORT',
    'send': 'STATUS_BAR.EXPORT',
  };
}
