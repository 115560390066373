import { Node } from "./status-bar.model";
export class User {
  createdAt: string;
  email: string;
  id: string;
  name: string;
  role: Role;
  brand: Brand;
  userName: string;
  userState: Node;
  userLang: number;
  masterLogin: string;
}

export class Role {
  id: string;
  slug: string;
}

export class Brand {
  accountNumber: number;
  accountName: string;
  accountId: number;
}
