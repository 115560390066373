<div class="get-started-options">

<h1 class="title">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.TITLE" | translate }}</h1>
  <div class="description">
    <p>{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.DESCRIPTION" | translate }}</p>
  </div>

  <div class="options-channel">
    <div class="options-container">
      <div class="option" id="get_start_btn_cat_type_basic"
           [ngClass]="{'selected': productCatalogType=='BASIC'}"
           (click)='nextOptionStep("BASIC")'
           (mouseover)="displayCatalogInfo(1)">
        <h2>{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.BASIC_CATALOG.TITLE" | translate }}</h2>
      </div>
      <div class="option" id="get_start_btn_cat_type_chan_spec"
           [ngClass]="{'selected': productCatalogType=='CHANNEL'}"
           (click)='nextOptionStep("CHANNEL")'
           (mouseover)="displayCatalogInfo(2)">
        <h2>{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.CHANNEL_SPECIFIC_CATALOG.TITLE" | translate }}</h2>
      </div>
    </div>
    <div class="text info">
      <p *ngIf="selectedUniversalCatalog">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.BASIC_CATALOG.INFO" | translate }}</p>
      <p *ngIf="selectedChannelCatalog">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.CHANNEL_SPECIFIC_CATALOG.INFO" | translate }}</p>
    </div>
    <div *ngIf="displayChannelSliders" >
      <div class="title">
        <h2>{{"GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.SELECT_CHANNEL" | translate }}</h2>
      </div>
      <div class="channels-sliders">
        <div *ngFor="let retailer of retailers">
          <mat-slide-toggle
            id="get_start_slider_{{retailer.name.toLowerCase()}}"
            [checked]="getSliderValue(retailer.id)"
            (change)="onChange(retailer.id, $event.checked)"
          >{{retailer.name}}</mat-slide-toggle>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="displayFileOptions" class="options">
    <div class="option" id="get_start_tab_download_template" (click)="nextStep('CreateAndDownload')">
      <h2>{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.CREATE_AND_DOWNLOAD.TITLE" | translate }}</h2>
      <p>{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.DESCRIPTION_OPTION_FOR_YOU" | translate }}</p>
      <div class="option-tab-info">
        <div class="tab">
          <i class="icon"><img src="assets/icons/checkmark_circle_12_regular.svg"></i>
          <span class="text">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.CREATE_AND_DOWNLOAD.INFO_1" | translate }}</span>
        </div>
        <div class="tab">
          <i class="icon"><img src="assets/icons/checkmark_circle_12_regular.svg"></i>
          <span class="text">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.CREATE_AND_DOWNLOAD.INFO_2" | translate }}</span>
        </div>
      </div>
        <div class="button-container">
          <button id="get_start_btn_download_template"  class="matt-button mat-raised-button" > {{"GET_STARTED_PAGE.GET_STARTED_OPTIONS.GET_STARTED_BUTTON_TEXT" | translate }}</button>
      </div>
    </div>

    <div class="option" id="get_start_tab_upload_panda" (click)="nextStep('PANDA')">
      <h2>{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.UPLOAD_PANDA.TITLE" | translate }}</h2>
      <p>{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.DESCRIPTION_OPTION_FOR_YOU" | translate }}</p>
      <div class="option-tab-info">
        <div class="tab">
          <i class="icon"><img src="assets/icons/checkmark_circle_12_regular.svg"></i>
          <span class="text">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.UPLOAD_PANDA.INFO_1" | translate }}</span>
        </div>
        <div class="tab">
          <i class="icon"><img src="assets/icons/checkmark_circle_12_regular.svg"></i>
          <span class="text">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.UPLOAD_PANDA.INFO_2" | translate }}</span>
        </div>
        <div class="tab">
          <i class="icon"><img src="assets/icons/checkmark_circle_12_regular.svg"></i>
          <span class="text">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.UPLOAD_PANDA.INFO_3" | translate }}</span>
        </div>
       </div>
        <div class="button-container">
          <button id="get_start_btn_upload_panda" class="matt-button mat-raised-button" > {{"GET_STARTED_PAGE.GET_STARTED_OPTIONS.GET_STARTED_BUTTON_TEXT" | translate }}</button>
        </div>
    </div>

    <div class="option" id="get_start_tab_upload_shopify" (click)="nextStep('SHOPIFY')">
      <h2>{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.UPLOAD_SHOPIFY.TITLE" | translate }}</h2>
      <p>{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.DESCRIPTION_OPTION_FOR_YOU" | translate }}</p>
      <div class="option-tab-info">
        <div class="tab">
          <i class="icon"><img src="assets/icons/checkmark_circle_12_regular.svg"></i>
          <span class="text">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.UPLOAD_SHOPIFY.INFO_1" | translate }}</span>
        </div>
        <div class="tab">
          <i class="icon"><img src="assets/icons/checkmark_circle_12_regular.svg"></i>
          <span class="text">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.UPLOAD_SHOPIFY.INFO_2" | translate }}</span>
        </div>
      </div>
        <div class="button-container">
          <button id="get_start_btn_upload_shopify"  class="matt-button mat-raised-button" > {{"GET_STARTED_PAGE.GET_STARTED_OPTIONS.GET_STARTED_BUTTON_TEXT" | translate }}</button>
        </div>
    </div>

    <div class="option" id="get_start_tab_upload_custom" (click)="nextStep('CUSTOM')">
      <h2>{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.UPLOAD_CUSTOM.TITLE" | translate }}</h2>
      <p>{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.DESCRIPTION_OPTION_FOR_YOU" | translate }}</p>
      <div class="option-tab-info">
        <div class="tab">
          <i class="icon"><img src="assets/icons/checkmark_circle_12_regular.svg"></i>
          <span class="text">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.UPLOAD_CUSTOM.INFO_1" | translate }}</span>
        </div>
        <div class="tab">
          <i class="icon"><img src="assets/icons/checkmark_circle_12_regular.svg"></i>
          <span class="text">{{ "GET_STARTED_PAGE.GET_STARTED_OPTIONS.OPTIONS_TABS.UPLOAD_CUSTOM.INFO_2" | translate }}</span>
        </div>

      </div>
      <div class="button-container">
        <button id="get_start_btn_upload_custom" class="matt-button mat-raised-button" > {{"GET_STARTED_PAGE.GET_STARTED_OPTIONS.GET_STARTED_BUTTON_TEXT" | translate }}</button>
      </div>
    </div>
  </div>
</div>
