<div class="node">
  <div class="icon">
    <span
      *ngIf="node?.status !== nodeStatusOptions?.COMPLETED"
      class="circle"
      [ngClass]="{ active: node?.status === nodeStatusOptions?.ACTIVE }"
      >{{ node?.order }}</span
    >
    <span *ngIf="node?.status === nodeStatusOptions?.COMPLETED">
      <app-icon name="checkmark_circle_20_filled" class="primary"></app-icon>
    </span>
  </div>
  <div class="node-text">{{ names[node?.slug] | translate }}</div>
</div>
