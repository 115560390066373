import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CatalogActions } from "@app/state/catalog/catalog.actions";
import { Store } from "@ngxs/store";

@Component({
  selector: 'app-reset-catalog-modal',
  templateUrl: './reset-catalog-modal.component.html',
  styleUrls: ['./reset-catalog-modal.component.scss'],
})
export class ResetCatalogModalComponent{

  constructor(private store: Store, public dialogRef: MatDialogRef<ResetCatalogModalComponent>,)
  { }

  closeModal() {
    this.dialogRef.close("close");
  }

  resetCatalog() {
    this.store.dispatch(new CatalogActions.ResetCatalog(this));
  }
}
