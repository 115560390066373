import {Component} from '@angular/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { StatusReportData } from 'src/app/models/status-report';
import { CatalogActions } from 'src/app/state/catalog/catalog.actions';
import { StatusReportActions } from 'src/app/state/status-report/status-report.actions';
import { StatusReportState } from 'src/app/state/status-report/status-report.state';
import {PandaRowUpdateData} from "@app/models/pandaRowUpdateData";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {
  UpdatesNotSavedModalComponent
} from "@app/pages/category/status-report/updates-not-saved-modal/updates-not-saved-modal.component";

@Component({
  selector: 'app-status-report',
  templateUrl: './status-report.component.html',
  styleUrls: ['./status-report.component.scss'],
})

export class StatusReportComponent  {
  @ViewSelectSnapshot(StatusReportState.statusReportData) statusReportData: StatusReportData;
  pandaRowsErrors: PandaRowUpdateData[];
  constructor(private store: Store, public dialog: MatDialog) {}

  continue(nextStep: number) {
    this.store.dispatch(new CatalogActions.GoToStep(nextStep));
  }

  downloadStatusReport() {
    this.store.dispatch(new StatusReportActions.DownloadStatusReport());
  }
  nextStep(nextStep: number){
    const dialogConfig = new MatDialogConfig();

    if (this.pandaRowsErrors == undefined){
      this.continue(nextStep);
    }
    if (this.pandaRowsErrors.length > 0) {
      dialogConfig.data = {
        pandaRowUpdates: this.pandaRowsErrors,
      };
      const dialogRef = this.dialog.open(UpdatesNotSavedModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result === 'continue') {
          this.continue(nextStep);
        }
      });
    }else{
      this.continue(nextStep);
    }
  }

  updatePandaRowsErrors(receivedPandaRowsErrors: PandaRowUpdateData[]){
    this.pandaRowsErrors = receivedPandaRowsErrors;
  }
}
