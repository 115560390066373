import { Transform, Type } from 'class-transformer';
import { User } from './user.model';
import { Node } from './status-bar.model';

export class CatalogData {
  catalogId: number = null;
  originalFileName: string = null;
  originalFileSize: number = null;
  columnsFound: number = null;
  computedRows: number = null;
  catalogType: string = null;
  @Type(() => UploadSetup)
  setup: UploadSetup = null;
  @Type(() => CatalogStateModel)
  state: CatalogStateModel = null;
  @Type(() => User)
  user: User = null;
  @Type(() => Node)
  maxState: Node;
  @Type(() => Date)
  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  categoriesModificationDate: Date;
  @Type(() => Date)
  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  optionalMappingsModificationDate: Date;
  @Type(() => Date)
  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  settingsModificationDate: Date;
  @Type(() => Date)
  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  uploadDate: Date;

  constructor(args: any = {}) {
    Object.assign(this, args);
  }
}

export class UploadSetup {
  charset: string;
  csvSeparator: string;
  textSeparator: string;

  constructor(args: any) {
    Object.assign(this, args);
  }
}

export class CatalogStateModel {
  name: string;
  order: number;
  slug: string;

  constructor(args: any) {
    Object.assign(this, args);
  }
}
