<div class="reset-modal">
  <div>
    <span>
      <h1 class="warning">
        {{
        "RESET_MENU.POP_UP.DESCRIPTION" | translate
        }}
      </h1>
    </span>
  </div>
  <div class="buttons-container">
      <button id="reset_modal_btn_cancel"
              class="modal-reset-button"
      (click)="closeModal()">
        <h1>{{"RESET_MENU.POP_UP.CANCEL_BTN" | translate}}</h1>
      </button>

      <button id="reset_modal_btn_proceed"
              class="modal-reset-button"
      (click)="resetCatalog()">
        <mat-icon class="warning">warning</mat-icon>
        <h1>{{"RESET_MENU.POP_UP.PROCEED_BTN" | translate}}</h1>
      </button>
  </div>
</div>
