import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'mappingName',
  pure: false,
})
export class MappingNamePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: any, ...args: unknown[]): unknown {
    if (
      this.translate.currentLang === 'en' ||
      this.translate.currentLang === undefined
    ) {
      return value.fieldNameEN;
    } else {
      return value.fieldNameDE;
    }
  }
}
