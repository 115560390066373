import {AfterViewInit, Component, Input, OnInit, OnChanges, ViewChild} from '@angular/core';
import {ATTRIBUTE_TYPE} from "@app/models/attribute.type";
import {FormControl} from "@angular/forms";
import {Observable, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import {MatSelect, MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-mandatory-columns',
  templateUrl: './mandatory-columns.component.html',
  styleUrls: ['./mandatory-columns.component.scss']
})
export class MandatoryColumnsComponent {
  @Input() mandatoryColumns: any[];
  @Input() columnNames: string[];
  @Input() popupOpened: boolean;
  @Input() userLang: number;
  @Input() topArrayMappings: Map<number,number>; // stores the current biggest array item index per array type attribute mapping
  @Input() isInRecommendedMode: boolean; // recommendedMappings is deprecated and we can put this component into a recommended mode

  public columnFilterCtrl: FormControl<string> = new FormControl<string>('');
  public filteredColumns: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  private filteredColumnsArray: string[];
  protected _onDestroy = new Subject<void>();

  private PLEASE_SELECT_EN = "Please select";
  private PLEASE_SELECT_DE = "Bitte auswählen";

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  constructor() {}

  static readonly MAX_ARRAY_ITEMS: number = 5; // TODO - separarte story to get from backend API

  ngOnInit(){
    this.columnFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterColumns();
      });
    this.filteredColumns.subscribe( list => this.filteredColumnsArray = list);
    this.initializeFilteredList();
  }

  initializeFilteredList(){
    // filling filtered list with all available column names
    this.filteredColumns.next(this.columnNames.slice());
  }

  ngAfterViewInit() {
    this.filteredColumns.next(this.columnNames.slice());
  }

  addMapping(column: any, i: number) {
    let mapping = this.mandatoryColumns[i];

    // clone the selected mapping
    let newMapping = JSON.parse(JSON.stringify(mapping));

    // initially unmapped
    newMapping.option = -1;

    // increment the index
    newMapping.arrayIndex = newMapping.arrayIndex + 1;

    // set the displayed column name. Dependens on language and, for array type, the index
    newMapping.displayColumn = this.getDisplayColumn(newMapping, this.userLang);

    //  Record the new biggest m,apped index
    this.topArrayMappings.set(column.attribute.id, newMapping.arrayIndex);

    // insert the new mapping at the appropriate plce in the mappings array
    this.mandatoryColumns.splice(i + 1, 0, newMapping);
  }

  deleteMapping(column: any, i: number) {
    this.mandatoryColumns.splice(i, 1);
    var topIndex = this.topArrayMappings.get(column.attribute.id);
    this.topArrayMappings.set(column.attribute.id, topIndex-1);
  }

  shouldDisplayAdd (column: any) : boolean {
    return column.attribute.attributeType == ATTRIBUTE_TYPE.ARRAY
      && column.arrayIndex == this.topArrayMappings.get(column.attribute.id)
      && column.arrayIndex < MandatoryColumnsComponent.MAX_ARRAY_ITEMS -1
      && column.option != -1
  }

  shouldDisplayDelete (column: any) : boolean {
    return column.attribute.attributeType == ATTRIBUTE_TYPE.ARRAY
      && column.arrayIndex == this.topArrayMappings.get(column.attribute.id)
      && column.arrayIndex != 0
  }

  shouldDisplayPleaseSelect (column: any) : boolean {
    return column.attribute.attributeType != ATTRIBUTE_TYPE.ARRAY
      ||
      ( column.attribute.attributeType == ATTRIBUTE_TYPE.ARRAY
        &&
        column.arrayIndex == this.topArrayMappings.get(column.attribute.id) )
  }

  getDisplayColumn(mappingValue: any, userLang: number): string {
    var displayColumn
    if (userLang == 0) {
      displayColumn = mappingValue.columnNameDE;
    }
    else {
      displayColumn = mappingValue.columnName;
    }

    // Will always be true currently since we only add array types
    if (mappingValue.attributeType == ATTRIBUTE_TYPE.ARRAY) {
      displayColumn = displayColumn + '{' + mappingValue.arrayIndex + '}';
    }

    return displayColumn;
  }

  protected filterColumns() {
    if (!this.columnNames) {
      return;
    }
    // get the search keyword
    let search = this.columnFilterCtrl.value;
    if (!search) {
      this.filteredColumns.next(this.columnNames);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredColumns.next(
      this.columnNames.filter(column => column.toLowerCase().indexOf(search) > -1)
    );
  }

  isFiltered(option: string){
    return this.filteredColumnsArray.find( filteredColumn => filteredColumn.toLowerCase().includes(option.toLowerCase()));
  }

  getValue(option: any, column: any ){
    if(option===this.PLEASE_SELECT_EN || option===this.PLEASE_SELECT_DE){
      return -1;
    }
    return option;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  changeIsUserMapped($event: MatSelectChange, column: any): void {
    column.isUserMapped = 1;
  }
}
