import { Category } from 'src/app/models/category.model';

export namespace CategoryActions {
  export class GetCategoryGroups {
    // Get from the backend
    static readonly type = 'CategoryActions.GetCategory';
  }

  export class ChangeViewedCategoryGroup {
    // Update on user click
    static readonly type = 'CategoryActions.ChangeSelectedCategory';

    constructor(public categoryId: number) {}
  }

  export class GetSelectedCategories {
    static readonly type = 'CategoryActions.GetSelectedCateogories';
  }

  export class SetSelectedCategories {
    // Send Selected Categories to backend
    static readonly type = 'CategoryActions.SetSelectedCateogories';
  }

  export class SetSelectedCategoriesAndDownloadTemplate {
    static readonly type = 'CategoryActions.SetSelectedCategoriesAndDownloadTemplate';
  }

  export class UpdateSelectedCategories {
    // Update local Category State
    static readonly type = 'CategoryActions.UpdateSelectedCateogories';

    constructor(public selectedCategory: Category, public categoryGroupId: number) {}
  }

  export class ToogleAllCategories {
    static readonly type ="CategoryActions.ToogleAllCategories"
    constructor(public value: boolean) {}
  }

  export class UpdateQuestionAnswer {
    static readonly type = "CategoryActions.UpdateQuestionAnswer";

    constructor(public selectedCategory: Category, public inSearchMode: boolean) {}
  }

  export class ValidateCategories {
    static readonly type = "CategoryActions.ValidateCategories";

    constructor(public categoryGroupId: number, public valid: boolean) {}
  }

  export class GetSearchBarCategories {
    static readonly type = "CategoryActions.GetSearchBarCategories";

    constructor(public filter: string) {}
  }

}
/*
mandatory-attributes
recomended-atributes
mandatory and not mandatory
optional are separate endpoints

recomended mappings first if there is no general
*/
