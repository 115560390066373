import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CatalogData } from 'src/app/models/catalog.model';
import { CatalogActions } from 'src/app/state/catalog/catalog.actions';
import { CatalogState } from 'src/app/state/catalog/catalog.state';
import { NavigationState } from 'src/app/state/navigation/navigation.state';

@Component({
  selector: 'app-category-container',
  templateUrl: './category-container.component.html',
  styleUrls: ['./category-container.component.scss'],
})
export class CategoryContainerComponent implements OnInit, OnDestroy {
  @Select(NavigationState.currentStep) currentStep: Observable<number>;
  @ViewSelectSnapshot(CatalogState.catalogData) catalogData: CatalogData;
  @ViewSelectSnapshot(NavigationState.templateFlow) templateFlow: boolean;
  @ViewSelectSnapshot(NavigationState.onStartPage) onStartPage: boolean;

  startPageUrl = '/get-started';
  url = '';
  private subscriptions = new Subscription();
  constructor(public router: Router, private store: Store) {}

  ngOnInit(): void {

    this.url = this.router.url;
    if(this.url)
    this.subscriptions.add(
      this.currentStep.subscribe(() => {
        this.url = this.router.url;

      })
    );

  }

  navigate() {
    if (this.templateFlow || this.catalogData.user.userState.order <= 1) {
      this.router.navigate([this.startPageUrl]);
    }
    else{

      this.store.dispatch(
        new CatalogActions.GoToStep(this.catalogData.user.userState.order - 1)
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
