import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select, Store } from '@ngxs/store';
import { concatMap, mergeMap, Observable, zip } from 'rxjs';
import { CategoryActions } from '../state/category/category.actions';
import { CategoryState } from '../state/category/category.state';

@Injectable({
  providedIn: 'root',
})
export class CategoryResolver implements Resolve<any[]> {
  @SelectSnapshot(CategoryState.selectedCategories)
  categories: Observable<any[]>;
  constructor(private store: Store) {}
  resolve(): Observable<any[]> {
    return this.store
      .dispatch(new CategoryActions.GetSelectedCategories())
  }
}
