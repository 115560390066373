import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { NavigationActions } from '../navigation/navigation.actions';
import { AuthActions } from './auth.actions';

export interface AuthStateModel {
  activeUser: any;
  accessToken: string;
  errorMessage: string;
}
@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    activeUser: null,
    accessToken: null,
    errorMessage: null,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private cookieService: CookieService,
    private router: Router,
    private authService: AuthService
  ) {}

  @Selector()
  static errorMessage(state: AuthStateModel) {
    return state.errorMessage;
  }

  @Selector()
  static accessToken(state: AuthStateModel) {
    return state.accessToken;
  }

  @Action(AuthActions.LoginUser)
  loginUser(
    ctx: StateContext<AuthStateModel>,
    { accessToken }: AuthActions.LoginUser
  ) {
    if (!environment.production) {
      return this.authService.login(accessToken).subscribe((data) => {
        const token = data.accessToken;
        ctx.patchState({ accessToken: token });
        ctx.dispatch(new AuthActions.SaveCookie());
        ctx.dispatch(new NavigationActions.LoadingEnd());
      })
    } else {
      ctx.patchState({ accessToken });
      ctx.dispatch(new AuthActions.SaveCookie());
      ctx.dispatch(new NavigationActions.LoadingEnd());
      return true;
    }
  }

  @Action(AuthActions.SaveCookie)
  saveCookie(ctx: StateContext<AuthStateModel>) {
    const token = ctx.getState().accessToken;
    if (token !== null) {
      this.cookieService.set(
        'jwt',
        token,
        3560,
        '/',
        undefined,
        undefined,
        undefined
      );
    }
  }

  @Action(AuthActions.GetCookie)
  getCookie(ctx: StateContext<AuthStateModel>) {
    const accessToken = this.cookieService.get('jwt');
    if (accessToken) {
      ctx.patchState({ accessToken });
    }
  }

  @Action(AuthActions.Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    this.cookieService.delete('jwt', "/");
    ctx.patchState({ accessToken: null });
    if (environment.production) {
      location.replace('https://tbone.trade-server.net/index.php');
    } else {
      this.router.navigate(['/redirect', "l"]);
    }

    //https://tbone.trade-server.net/?redirect_target=import_wizard
  }
}
