import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS, HttpClientXsrfModule,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { States } from './state/state';
import { CoreModule } from './pages/core/core.module';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { GetStartedComponent } from './pages/get-started/get-started.component';
import { GetStartedOptionsComponent } from './pages/get-started/get-started-options/get-started-options.component';
import {MatRadioModule} from "@angular/material/radio";
import {XsrfInterceptor} from "@app/interceptors/csrf.interceptor";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ResetCatalogModalComponent} from "@app/pages/core/navbar/reset-catalog-modal/reset-catalog-modal.component";
import {MatIconModule} from "@angular/material/icon";
import {CategoryModule} from "@app/pages/category/category.module";
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [AppComponent, GetStartedComponent, GetStartedOptionsComponent, ResetCatalogModalComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        HttpClientXsrfModule,
        MatProgressSpinnerModule,
        NgxsModule.forRoot(States, {
            developmentMode: !environment.production,
        }),
        NgxsSelectSnapshotModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        // NgxsLoggerPluginModule.forRoot(),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        MatRadioModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatIconModule,
        CategoryModule,
    ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: XsrfInterceptor,
    multi: true,
    },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
