import { Component, Input, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Store } from '@ngxs/store';
import { Category } from 'src/app/models/category.model';
import { CategoryActions } from 'src/app/state/category/category.actions';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input() category: Category;
  @Input() categoryId: number;
  @Input('searchModeInd') searchModeInd: boolean;

  questionText: string;
  questions = {
    "one-size-belts": "CATALOG_CATEGORIES.QUESTIONS.ONE_SIZE_BELTS",
    "beauty-textile": "CATALOG_CATEGORIES.QUESTIONS.BEAUTY_TEXTILE",
    "cup-size-swimwear": "CATALOG_CATEGORIES.QUESTIONS.CUP_SIZE_SWIMWEAR",
    "cup-size-bra": "CATALOG_CATEGORIES.QUESTIONS.CUP_SIZE_BRA",
    "trouser-length-size": "CATALOG_CATEGORIES.QUESTIONS.TROUSER_LENGTH_SIZE"
  }
  constructor(public store: Store) { }

  ngOnInit(): void {
    this.questionText = this.questions[this.category.questionSlug];
  }

  changeQuestionAnswer(event: MatRadioChange) {
    this.store.dispatch(new CategoryActions.UpdateQuestionAnswer({
      ...this.category,
      questionAnswer: event.value,
    }, this.searchModeInd))
  }

}
