import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { NodeStatus } from 'src/app/models/status-bar.model';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NavigationActions } from './navigation.actions';
import { patch, append, removeItem } from '@ngxs/store/operators';

export interface NavigationStateModel {
  loading: boolean;
  navbar: any;
  activeCategoryPageIndex: number;
  loadingQue: any[];
  onStartPage: boolean;
  templateFlow: boolean;

}

@State<NavigationStateModel>({
  name: 'navigation',
  defaults: {
    loading: false,
    navbar: {},
    activeCategoryPageIndex: -1,
    loadingQue: [],
    onStartPage: false,
    templateFlow: false
  },
})
@Injectable()
export class NavigationState {
  nodeStatus = NodeStatus;
  constructor(
    public navigationService: NavigationService,
    public router: Router,
    private authHelper: AuthHelperService
  ) {}

  @Selector()
  static loading(state: NavigationStateModel) {
    return state.loading;
  }

  @Selector()
  static onStartPage(state: NavigationStateModel) {
    return state.onStartPage;
  }

  @Selector()
  static currentStep(state: NavigationStateModel) {
    return state.activeCategoryPageIndex;
  }

  @Selector()
  static templateFlow(state: NavigationStateModel) {
    return state.templateFlow;
  }

  @Action(NavigationActions.LoadingStart)
  startNavigation(ctx: StateContext<NavigationStateModel>) {
    ctx.patchState({ loading: ctx.getState().loadingQue.length === 0 });
  }
  @Action(NavigationActions.LoadingStart)
  endNavigation(ctx: StateContext<NavigationStateModel>) {
    ctx.patchState({ loading: ctx.getState().loadingQue.length !== 0 });
  }

  @Action(NavigationActions.EnterStartPage)
  enterStartPage(ctx: StateContext<NavigationStateModel>) {
    ctx.patchState({ onStartPage: true });
  }

  @Action(NavigationActions.LeaveStartPage)
  leaveStartPage(ctx: StateContext<NavigationStateModel>) {
    ctx.patchState({ onStartPage: false });
  }

  @Action(NavigationActions.SetCurrentStep)
  setCurrentStep(
    ctx: StateContext<NavigationStateModel>,
    { step }: NavigationActions.SetCurrentStep
  ) {
    ctx.patchState({ activeCategoryPageIndex: step });
  }

  @Action(NavigationActions.NavigateToCurrentStep)
  navigateToCurrentStep(ctx: StateContext<NavigationStateModel>) {}

  @Action(NavigationActions.NavigateToNextStep)
  navigateToNextStep(
    ctx: StateContext<NavigationStateModel>,
    { url }: NavigationActions.NavigateToNextStep
  ) {
    let activeCategoryPageIndex = ctx.getState().activeCategoryPageIndex;
    activeCategoryPageIndex += 1;
    ctx.patchState({ activeCategoryPageIndex });
    this.authHelper.redirectTo(`/catalog/${url}`);
  }

  @Action(NavigationActions.AddToLoadingQue)
  addToLoadingQue(
    ctx: StateContext<NavigationStateModel>,
    { url }: NavigationActions.AddToLoadingQue
  ) {
    ctx.setState(
      patch({
        loadingQue: append<string>([url]),
      })
    );
    ctx.dispatch(new NavigationActions.LoadingStart());
  }

  @Action(NavigationActions.RemoveFromLoadingQue)
  removeFromLoadingQue(
    ctx: StateContext<NavigationStateModel>,
    { url }: NavigationActions.RemoveFromLoadingQue
  ) {
    ctx.setState(
      patch({
        loadingQue: removeItem((item) => item === url),
        loading: ctx.getState().loadingQue.length - 1 > 0
      })
    )
    ctx.dispatch(new NavigationActions.LoadingEnd());
  }

  @Action(NavigationActions.SetTemplateFlow)
  setTemplateFlow(ctx: StateContext<NavigationStateModel>, { setTemplateFlow }: NavigationActions.SetTemplateFlow) {
    ctx.patchState({ templateFlow: setTemplateFlow });
  }
}
