import { Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { OptionalMapping } from 'src/app/models/optional-mappings';
import {
  AuthHelperService,
  SaveOptions,
} from 'src/app/services/auth-helper.service';
import { CategoryService } from 'src/app/services/category.service';
import { CatalogActions } from '../../catalog/catalog.actions';
import { CatalogState, CatalogStateModel } from '../../catalog/catalog.state';
import { CategoryMappingsActions } from './category-mappings.actions';
import {CatalogMapping} from "@app/models/catalog-mappings";
import {catchError, EMPTY} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ATTRIBUTE_TYPE} from "@app/models/attribute.type";

export interface CategoryMappingsStateModel {
  mandatory: any[]; // Mandatory attributes (taking into  account selected categories)
  recomended: any[]; // Recommended attributes (taking into  account selected categories)
  columnNames: any[];  // Full set of columns names from the uploaded file
  recomendedMappings: any[]; // These are not actual mappings, just recommendation for mappings for attributes (manadatory and recommended)
  generalMappings: any[]; // These are actual mappings (manadatory and recommended)
  optionalMappings: any[]; // These are actual mappings (optional)
  catalogMapping: CatalogMapping;
}

@State<CategoryMappingsStateModel>({
  name: 'categoryMapping',
  defaults: {
    mandatory: [],
    recomended: [],
    columnNames: [],
    recomendedMappings: [],
    generalMappings: [],
    optionalMappings: [],
    catalogMapping: new CatalogMapping()
  },
})
@Injectable()
export class CategoryMappingsState {
  constructor(
    private categoryService: CategoryService,
    private authHelp: AuthHelperService,
    private store: Store,
    private snackBar: MatSnackBar,
    private zone: NgZone,
  ) {
  }

  static readonly GERMAN_LANG : number = 0;

  @Selector()
  static columnNames(state: CategoryMappingsStateModel) {
    return state.columnNames;
  }

  @Selector()
  static optionalColumnNames(state: CategoryMappingsStateModel) {
    const newColumnNames = [...state.columnNames];
    const optionalMappings = state.optionalMappings.map(
      (mapping) => mapping.columnName
    );
    const generalMappings = state.generalMappings.map(
      (mapping) => mapping.option
    );

    return newColumnNames.filter(
      (mapping) => !optionalMappings.includes(mapping) && !generalMappings.includes(mapping)
    );
  }

  @Selector()
  static optionalMappings(state: CategoryMappingsStateModel) {
    const newOptional: OptionalMapping[] = [];
    state.optionalMappings.forEach((mapping) =>
      newOptional.push(Object.assign({}, mapping))
    );
    return newOptional;
  }

  @Selector([CatalogState])
  static attributesMapping(
    state: CategoryMappingsStateModel,
    catalogState: CatalogStateModel
  ) {

    let unTouchedRecMappings = state.recomendedMappings.filter(a => a.isUserMapped === 0);
    let genAttrIds = state.generalMappings.map(a => a.attribute.id);

    unTouchedRecMappings = unTouchedRecMappings.filter(r => !genAttrIds.includes(r.attribute.id ));

    const mappedData = state.generalMappings.concat(unTouchedRecMappings);

    return this.createMappings(
        {recommended: state.recomended, mandatory: state.mandatory},
        mappedData,
        catalogState.catalogData.catalogId,
        catalogState.catalogData.user.userLang
    );

  }


  static createMappings(
    mappings: { recommended: any[]; mandatory: any[] },
    mappingsValues: any[],
    catalogId: number,
    userLang: number
  ) {

    // topArrayMapping contains the highest mapped arrayIndex per arrayType mapping.
    //Used to determine whether to display the Add/Delete array mapping options
    const topArrayMapping = new Map<number, number>();

    // initialise topArrayMapping to 0 for all items in the attribute lists
    const mandatoryArrayAttrs = mappings.mandatory.filter((mapping) => mapping.attributeType == ATTRIBUTE_TYPE.ARRAY);
    mandatoryArrayAttrs.forEach((attr) => {
      topArrayMapping.set(attr.id, 0);
    });

    const recommendedArrayAttrs = mappings.recommended.filter((mapping) => mapping.attributeType == ATTRIBUTE_TYPE.ARRAY);
    recommendedArrayAttrs.forEach((attr) => {
      topArrayMapping.set(attr.id, 0);
    });

    // now set topArrayMapping according to actual mappings/recommendations
    const mappingValuesForArrayAttr = mappingsValues.filter((mappingValue) => mappingValue.attribute.attributeType == ATTRIBUTE_TYPE.ARRAY);
    mappingValuesForArrayAttr.forEach((arrayMappingValue) => {
      const index = arrayMappingValue.arrayIndex;
      if (topArrayMapping.has(arrayMappingValue.attribute.id)) {
        if (topArrayMapping.get(arrayMappingValue.attribute.id) < index) {
          topArrayMapping.set(arrayMappingValue.attribute.id, index)
        }
      } else {
        topArrayMapping.set(arrayMappingValue.attribute.id, index)
      }
    });

    // Now merge the attribute lists with the mappings/recommendations to get the mappings to display initially
    const newMandatory = this.processMappings(mappings.mandatory, mappingsValues, catalogId, userLang);
    const newRecommended = this.processMappings(mappings.recommended, mappingsValues, catalogId, userLang);

    return {mandatory: newMandatory, recommended: newRecommended, topArrayMappings: topArrayMapping};
  }

  static processMappings(attributes: any[], mappings: any[], catalogId: number, userLang: number ): any[] {

    const mappingsToDisplay: any[] = [];

    const attributeMappings = CategoryMappingsState.getMappingsForAttributes(attributes, mappings);
    attributes.forEach((field) => {


      if (attributeMappings.has(field.id)) {
        const mappingValuesForAttr = attributeMappings.get(field.id);

        mappingValuesForAttr.forEach( (mappingValue) => {
          const mandatoryValue = mappingValue?.option?.option
            ? mappingValue?.option?.option
            : mappingValue?.option;
          mappingsToDisplay.push({
            ...field,
            option: mandatoryValue || -1,
            isUserMapped: mappingValue.isUserMapped || 0,
            catalogId: catalogId,
            mappingId: mappingValue?.id,
            arrayIndex: (mappingValue?.arrayIndex as number),
            displayColumn: CategoryMappingsState.getDisplayName(field, mappingValue, userLang),
            attribute: field
          });
        });
      }
      else {
        mappingsToDisplay.push({
          ...field,
          option: -1,
          catalogId: catalogId,
          isUserMapped: 0,
          arrayIndex: field.attributeType == ATTRIBUTE_TYPE.ARRAY ? 0 : -1,
          displayColumn: CategoryMappingsState.getDisplayName(field, {arrayIndex:0}, userLang),
          attribute: field
        });
      }
    });

    return mappingsToDisplay;
  }


  static getDisplayName(field: any, mappingValue: any, userLang: number): string {
    var displayColumn
    if (userLang == this.GERMAN_LANG) {
      displayColumn = field.columnNameDE;
    }
    else {
      displayColumn = field.columnName;
    }

    if (field.attributeType == ATTRIBUTE_TYPE.ARRAY ) {
      var arrayIndex = mappingValue.arrayIndex;
      displayColumn = displayColumn + '{' + arrayIndex + '}';
    }

    return displayColumn;
  }

  static getMappingsForAttributes(attributes:any[], mappings:any[]): Map<number,any[]> {
    const attributeMappings = new Map<number,any[]>();
    attributes.forEach((attribute) => {
      const mappingValuesForAttr = mappings.filter((mapping) => mapping.attribute.id == attribute.id);
      if (mappingValuesForAttr.length > 0) {
        attributeMappings.set(attribute.id, mappingValuesForAttr);
      }
    });

    return attributeMappings;
  }

  @Action(CategoryMappingsActions.GetMandatoryAttributes)
  getMandatoryAttributes(
    ctx: StateContext<CategoryMappingsStateModel>,
    {catalogId}: CategoryMappingsActions.GetMandatoryAttributes
  ) {
    return this.categoryService
      .getMandatoryAttributes(catalogId)
      .subscribe((mandatory: any[]) => {
        ctx.patchState({mandatory});
      });
  }

  @Action(CategoryMappingsActions.GetRecomendedAttributes)
  getRecomendedAttributes(
    ctx: StateContext<CategoryMappingsStateModel>,
    {catalogId}: CategoryMappingsActions.GetRecomendedAttributes
  ) {
    return this.categoryService
      .getRecomendedAttributes(catalogId)
      .subscribe((recomended) => {
        ctx.patchState({
          recomended,
        });
      });
  }

  @Action(CategoryMappingsActions.GetColumnNames)
  getColumnNames(
    ctx: StateContext<CategoryMappingsStateModel>,
    {catalogId}: CategoryMappingsActions.GetColumnNames
  ) {
    return this.categoryService
      .getColumnNames(catalogId)
      .subscribe((columnNames) => {
        ctx.patchState({columnNames});
      });
  }

  @Action(CategoryMappingsActions.GetRecommendedMappings)
  getRecommendedMappings(
    ctx: StateContext<CategoryMappingsStateModel>,
    {catalogId}: CategoryMappingsActions.GetRecommendedMappings
  ) {
    return this.categoryService
      .getRecommendedMappings(catalogId)
      .subscribe((recomendedMappings) => {
        ctx.patchState({recomendedMappings});
      });
  }


  @Action(CategoryMappingsActions.GetCatalogMappings)
  getCatalogMappings(
    ctx: StateContext<CategoryMappingsStateModel>,
    {catalogId}: CategoryMappingsActions.GetCatalogMappings,
  ) {
    return this.categoryService
      .getCatalogMappings(catalogId)
      .subscribe((catalogMapping) => {
        catalogMapping.optionalMappings.forEach((mapping) => (mapping.disabled = true));
        ctx.patchState(catalogMapping);
      });
  }

  @Action(CategoryMappingsActions.GetInitialData)
  getInitialData(
    ctx: StateContext<CategoryMappingsStateModel>,
    {catalogId}: CategoryMappingsActions.GetInitialData
  ) {
    ctx.dispatch([
      new CategoryMappingsActions.GetCatalogMappings(catalogId),
      new CategoryMappingsActions.GetRecommendedMappings(catalogId),
      new CategoryMappingsActions.GetRecomendedAttributes(catalogId),
      new CategoryMappingsActions.GetColumnNames(catalogId),
      new CategoryMappingsActions.GetMandatoryAttributes(catalogId),
    ]);
  }

  @Action(CategoryMappingsActions.SetCatalogMappings)
  setCatalogMappings(
    ctx: StateContext<CategoryMappingsStateModel>,
    {catalogMapping}: CategoryMappingsActions.SetCatalogMappings,
  ) {
    this.categoryService.setCatalogMappings(catalogMapping).pipe(
      catchError((error: HttpErrorResponse) => {
        this.zone.run(() => {
          console.log("Error occurred in setCatalogMappings : " + error.message);
          this.snackBar.open("Failed to update mappings ("  + error.message + ")", 'Close');
        });
        return EMPTY;
      })
    ).subscribe((data) => {
        const userOrder = this.store.selectSnapshot(CatalogState.catalogData).user
          .userState.order;
        ctx.dispatch([ new CatalogActions.GoToStep(userOrder + 1) ]);
      }
    );
  }

  @Action(CategoryMappingsActions.UpdateOptionalMappings)
  updateOptionalMappings(
    ctx: StateContext<CategoryMappingsStateModel>,
    { optionalMappings }: CategoryMappingsActions.UpdateOptionalMappings
  ) {
    ctx.patchState({ optionalMappings });
  }

  @Action(CategoryMappingsActions.SetMappings)
  setMappings(
    ctx: StateContext<CategoryMappingsStateModel>,
    { catalogMapping }: CategoryMappingsActions.SetCatalogMappings
  ) {
    this.authHelp.saveSettingsHelper(SaveOptions.mappings).subscribe((res) => {
      if (res) {
        ctx.dispatch([
          new CategoryMappingsActions.SetCatalogMappings(catalogMapping)
        ]);
      }
    });
  }
  @Action(CategoryMappingsActions.DeleteOptionalMapping)
  deleteOptionalMapping(
    ctx: StateContext<CategoryMappingsStateModel>,
    { columnName, catalogId }: CategoryMappingsActions.DeleteOptionalMapping
  ) {
    this.authHelp.saveSettingsHelper(SaveOptions.mappings).subscribe((res) => {
      if (res) {
        this.categoryService
          .deleteOptionalMapping(columnName, catalogId)
          .subscribe((data) => {
            ctx.dispatch(new CatalogActions.GetCatalogState());
          });
      }
    });
  }
}
