import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CatalogData } from '../models/catalog.model';
import { StatusReportActions } from '../state/status-report/status-report.actions';

@Injectable({
  providedIn: 'root',
})
export class StatusReportResolver implements Resolve<CatalogData> {
  constructor(private store: Store) {}
  resolve(): Observable<CatalogData> {
    return this.store.dispatch(new StatusReportActions.GetStatusReportData());
  }
}
