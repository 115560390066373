import {
  Component,
  Input, ViewChild
} from '@angular/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { CatalogData } from 'src/app/models/catalog.model';
import { OptionalMapping } from 'src/app/models/optional-mappings';
import { CatalogState } from 'src/app/state/catalog/catalog.state';
import { CategoryMappingsActions } from 'src/app/state/category/category-mappings/category-mappings.actions';
import { CategoryMappingsState } from 'src/app/state/category/category-mappings/category-mappings.state';
import {FormControl} from "@angular/forms";
import {ReplaySubject, Subject, takeUntil} from "rxjs";
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-optional-mappings',
  templateUrl: './optional-mappings.component.html',
  styleUrls: ['./optional-mappings.component.scss'],
})
export class OptionalMappingsComponent {
  @ViewSelectSnapshot(CatalogState.catalogData) catalogData: CatalogData;
  @ViewSelectSnapshot(CategoryMappingsState.optionalColumnNames)
  optionalColumnNames: string[];
  @Input() optionalMappings: OptionalMapping[];
  @Input() columnNames: string[];
  @Input() optionalMappingErrors: Array<string>;

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  selectedIndex: number

  static P_COMP = 'p_comp';
  static P_TAG = 'p_tag';

  static COMP_REGEX = new RegExp('^' + OptionalMappingsComponent.P_COMP + '\\[(.*)\\]$',"i");
  static TAG_REGEX = new RegExp('^'  + OptionalMappingsComponent.P_TAG +  '\\[(.*)\\]({(.*)})?$',"i");

  static COMPONENT = "COMPONENT";
  static ATTRIBUTE = "ATTRIBUTE";

  public optionalColumnFilterCtrl: FormControl<string> = new FormControl<string>('');
  public filteredOptionalColumns: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  private filteredOptionalColumnsArray: string[];
  protected _onDestroy = new Subject<void>();

  constructor(private store: Store) {}

  addOptionalMappingForm(mapping?: OptionalMapping) {
    this.optionalMappings.push({
      catalogId: mapping?.catalogId || this.catalogData.catalogId,
      columnName: mapping?.columnName || '',
      description: mapping?.description || '',
      mappingType: mapping?.mappingType || OptionalMappingsComponent.COMPONENT,
      disabled: false,
    });
  }

  deleteColumn(optionalMapping: OptionalMapping, i: number) {
    if (optionalMapping.disabled) {
      this.store.dispatch(
        new CategoryMappingsActions.DeleteOptionalMapping(
          optionalMapping.columnName,
          this.catalogData.catalogId
        )
      )
    } else {
      this.optionalMappings.splice(i, 1);
      if (this.optionalMappings.length == 0 ) {
        // No optional mappings left so can remove the error
        this.optionalMappingErrors.splice(0);
      }
    }

  }

  optionSelected(event: any, selectedIndex: any) {
    this.selectedIndex = selectedIndex;
  }

  selectionChanged( event: any ) {
    this.setDescription(event.value);
  }

  setDescription(selectedColumn: string) {

    var desc;

    if (this.optionalMappings[this.selectedIndex].mappingType == OptionalMappingsComponent.COMPONENT && OptionalMappingsComponent.COMP_REGEX.test(selectedColumn) ) {
      desc = selectedColumn.match(OptionalMappingsComponent.COMP_REGEX)[1];
    }
    else if (this.optionalMappings[this.selectedIndex].mappingType == OptionalMappingsComponent.ATTRIBUTE && OptionalMappingsComponent.TAG_REGEX.test(selectedColumn) ) {
      desc = selectedColumn.match(OptionalMappingsComponent.TAG_REGEX)[1];
    }
    else {
      desc = selectedColumn;
    }

    this.optionalMappings[this.selectedIndex].description = desc;
  }

  getPandaColumn(option: OptionalMapping) {
    if(option.mappingType == OptionalMappingsComponent.COMPONENT) {
      return OptionalMappingsComponent.P_COMP + '[' + option.description + ']';
    }
    else {
      return OptionalMappingsComponent.P_TAG + '[' + option.description + ']{0}';
    }
  }

  radioChange(event: any, selectedIndex: any) {
    this.selectedIndex = selectedIndex;
  }

  ngOnInit(){
    this.optionalColumnFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterColumns();
      });
    this.filteredOptionalColumns.subscribe(list => this.filteredOptionalColumnsArray = list);
  }

  initializeFilteredList(){
    // filling filtered list with all available column names
    this.filteredOptionalColumns.next(this.optionalColumnNames.slice());
  }

  ngAfterViewInit() {
    this.filteredOptionalColumns.next(this.optionalColumnNames.slice());
  }
  protected filterColumns() {
    if (!this.optionalColumnNames) {
      return;
    }
    // get the search keyword
    let search = this.optionalColumnFilterCtrl.value;
    if (!search) {
      this.filteredOptionalColumns.next(this.optionalColumnNames);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredOptionalColumns.next(
      this.optionalColumnNames.filter(column => column.toLowerCase().indexOf(search) > -1)
    );
  }

  isFiltered(option: string){
    return this.filteredOptionalColumnsArray.find( filteredColumn => filteredColumn.toLowerCase().includes(option.toLowerCase()));
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
