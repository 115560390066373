import {Injectable, NgZone, TRANSLATIONS} from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  Category,
  CategoryGroup,
  SelectedCategory,
} from 'src/app/models/category.model';
import { CategoryService } from 'src/app/services/category.service';
import { CategoryActions } from './category.actions';
import produce from 'immer';
import { CategoryMappingsState } from './category-mappings/category-mappings.state';
import { CatalogState } from '../catalog/catalog.state';
import { CatalogActions } from '../catalog/catalog.actions';
import {
  AuthHelperService,
  SaveOptions,
} from 'src/app/services/auth-helper.service';
import {PandaTemplateService} from "@app/services/panda-template.service";
import {FileSaverService} from "ngx-filesaver";
import { TranslateService } from '@ngx-translate/core';

export interface CategoryStateModel {
  categoryGroups: CategoryGroup[];
  viewedCategoryGroupId: number;
  selectedCategories: SelectedCategory[];
  selectedCategoryCount: Record<number, number>;
  searchBarResults: Category[];
}
@State<CategoryStateModel>({
  name: 'category',
  defaults: {
    categoryGroups: null,
    viewedCategoryGroupId: null,
    selectedCategories: [],
    selectedCategoryCount: {},
    searchBarResults: [],
  },
  children: [CategoryMappingsState],
})
@Injectable()
export class CategoryState {
  constructor(
    private translate: TranslateService,
    private categoryService: CategoryService,
    private store: Store,
    private authHelp: AuthHelperService,
    private pandaTemplateService: PandaTemplateService,
    private fileSaver: FileSaverService,
  ) {}

  private CAT_GROUP_PREFIX = "CATALOG_CATEGORIES.CATEGORY_GROUP.";
  private CATEGORIES_PREFIX = "CATALOG_CATEGORIES.CATEGORY.";
  @Selector()
  static viewedCategory(state: CategoryStateModel) {
    const viewedCategory = [
      ...state.categoryGroups.find((c) => c.id === state.viewedCategoryGroupId)
        .categories,
    ];
    return {
      categories: viewedCategory,
      categoryId: state.viewedCategoryGroupId,
    };
  }

  @Selector()
  static searchResults(state: CategoryStateModel) {
    return {
      categories: state.searchBarResults,
    };
  }

  @Selector()
  static selectedCategoryCount(state: CategoryStateModel) {
    return state.selectedCategoryCount;
  }

  @Selector()
  static categoryGroups(state: CategoryStateModel) {
    return state.categoryGroups;
  }

  @Selector()
  static selectedCategories(state: CategoryStateModel) {
    return state.selectedCategories;
  }

  @Action(CategoryActions.GetCategoryGroups)
  getCategoryGroups(ctx: StateContext<CategoryStateModel>) {
    return this.categoryService.getCategoryGroups().subscribe((categories) => {
      const newCateogries = categories.map((c) => {
        return { ...c, valid: true };
      });
      // Categories selected but who knows in which group
      const selectedCategories = ctx.getState().selectedCategories;
      const selectedCategoryCount: Record<number, number> = {};
      newCateogries.forEach((categorygroup) => {
        selectedCategoryCount[categorygroup.id] = 0;
        categorygroup.categories.forEach((category) => {
          const isSelected = selectedCategories.find(
            (c) => c.categoryId === category.id
          );
          if (!!isSelected) {
            category.selected = true;
            category.questionAnswer = isSelected?.answer;
            selectedCategoryCount[categorygroup.id] += 1;
          } else {
            category.selected = false;
            category.questionAnswer = null;
          }
        });
      });
      ctx.patchState({
        categoryGroups: categories.sort((a, b) => this.translate.instant(this.CAT_GROUP_PREFIX + a.slug).localeCompare(this.translate.instant(this.CAT_GROUP_PREFIX  + b.slug))),
        viewedCategoryGroupId: newCateogries[0].id,
        selectedCategoryCount: selectedCategoryCount,
      });
      ctx.dispatch(
        new CategoryActions.ChangeViewedCategoryGroup(newCateogries[0].id)
      );
    });
  }

  @Action(CategoryActions.ChangeViewedCategoryGroup)
  changeViewedCategory(
    ctx: StateContext<CategoryStateModel>,
    { categoryId }: CategoryActions.ChangeViewedCategoryGroup
  ) {
    if (categoryId !== -1) {
      const cId = ctx
        .getState()
        .categoryGroups.findIndex((c) => c.id === categoryId);
      const selectedCategories = ctx
        .getState()
        .categoryGroups[cId].categories.filter((c) => c.selected)
        .sort((a, b) => this.translate.instant(this.CATEGORIES_PREFIX + a.slug).localeCompare(this.translate.instant(this.CATEGORIES_PREFIX  + b.slug)));
      const unselectedCategories = ctx
        .getState()
        .categoryGroups[cId].categories.filter((c) => !c.selected)
        .sort((a, b) => this.translate.instant(this.CATEGORIES_PREFIX  + a.slug).localeCompare(this.translate.instant(this.CATEGORIES_PREFIX  + b.slug)));
      ctx.setState(
        produce((draft) => {
          draft.categoryGroups[cId].categories = [
            ...selectedCategories,
            ...unselectedCategories,
          ];
        })
      );
    }
    ctx.patchState({ viewedCategoryGroupId: categoryId });
  }

  @Action(CategoryActions.GetSelectedCategories)
  getSelectedCategories(ctx: StateContext<CategoryStateModel>) {
    return this.categoryService
      .getSelectedCategories()
      .subscribe((selectedCategories) => {
        ctx.patchState({ selectedCategories });
        this.store.dispatch(new CategoryActions.GetCategoryGroups());
      });
  }

  @Action(CategoryActions.SetSelectedCategories)
  setSelectedCategories(ctx: StateContext<CategoryStateModel>) {
    let gotoNextStepFunction = () => {
      const userOrder = this.store.selectSnapshot(CatalogState.catalogData).user.userState.order;
      ctx.dispatch([
        new CatalogActions.GetCatalogState(),
        new CatalogActions.GoToStep(userOrder + 1),
      ]);
    };
    this.saveCategoriesAndPostProcess(ctx, gotoNextStepFunction );
  }

  @Action(CategoryActions.SetSelectedCategoriesAndDownloadTemplate)
  setSelectedCategoriesAndDownloadTemplate(ctx: StateContext<CategoryStateModel>) {
    let downloadTemplateFunction = () => {
                  ctx.dispatch([
                    new CatalogActions.GetCatalogState(),
                    new CatalogActions.DownloadPandaTemplate()
                ]);
                };
    this.saveCategoriesAndPostProcess(ctx, downloadTemplateFunction );
  }

  saveCategoriesAndPostProcess(ctx: StateContext<CategoryStateModel>, postProcessFunction: Function ) {
    const categoryGroups = ctx.getState().categoryGroups;
    const selectedCategories: SelectedCategory[] = [];
    this.authHelp
      .saveSettingsHelper(SaveOptions.categories)
      .subscribe((res) => {
        if (res) {
          categoryGroups.forEach((categoryGroup) => {
            categoryGroup.categories.forEach((category, i) => {
              if (category.selected) {
                const selectedCategory = {
                  answer: category.questionAnswer,
                  categoryId: category.id,
                };
                selectedCategories.push(selectedCategory);
              }
            });
          });
          this.categoryService
            .setSelectedCategories(selectedCategories)
            .subscribe(() => { postProcessFunction() });
        }
      });
  }


  @Action(CategoryActions.ToogleAllCategories)
  toogleAll(
    ctx: StateContext<CategoryStateModel>,
    { value }: CategoryActions.ToogleAllCategories
  ) {
    const selectdeGroupId = ctx
      .getState()
      .categoryGroups.findIndex(
        (categoryGroup) =>
          categoryGroup.id === ctx.getState().viewedCategoryGroupId
      );
    ctx.setState(
      produce((draft) => {
        const newC = draft.categoryGroups[selectdeGroupId].categories.map(
          (category) => {
            return { ...category, selected: value };
          }
        );
        draft.categoryGroups[selectdeGroupId].categories = newC;
        if (value) {
          draft.selectedCategoryCount[draft.viewedCategoryGroupId] =
            draft.categoryGroups[selectdeGroupId].categories.length;
        } else {
          draft.selectedCategoryCount[draft.viewedCategoryGroupId] = 0;
        }
      })
    );
  }

  @Action(CategoryActions.UpdateQuestionAnswer)
  updateQuestionAnswers(
    ctx: StateContext<CategoryStateModel>,
    { selectedCategory , inSearchMode}: CategoryActions.UpdateQuestionAnswer
  ) {
    const state = ctx.getState();
    let selectedGroupId: number;
    if(inSearchMode){
      selectedGroupId = selectedCategory.categoryGroupId -1;
    }else{
      selectedGroupId = state.categoryGroups.findIndex(
        (categoryGroup) =>
         categoryGroup.id === ctx.getState().viewedCategoryGroupId
      );
    }
    const categoryId = state.categoryGroups[
      selectedGroupId
    ].categories.findIndex((c) => c.id === selectedCategory.id);
    ctx.setState(
      produce((draft) => {
        draft.categoryGroups[selectedGroupId].categories[categoryId] =
          selectedCategory;
      })
    );
  }

  @Action(CategoryActions.UpdateSelectedCategories)
  updateSelectedCategories(
    ctx: StateContext<CategoryStateModel>,
    {
      selectedCategory,
      categoryGroupId,
    }: CategoryActions.UpdateSelectedCategories
  ) {
    const state = ctx.getState();
    const selectdeGroupId = state.categoryGroups.findIndex(
      (categoryGroup) => categoryGroup.id === categoryGroupId
    );
    const categoryId = state.categoryGroups[
      selectdeGroupId
    ].categories.findIndex((c) => c.id === selectedCategory.id);
    ctx.setState(
      produce((draft) => {
        draft.categoryGroups[selectdeGroupId].categories[categoryId] =
          selectedCategory;
        if (selectedCategory.selected) {
          draft.selectedCategoryCount[categoryGroupId] += 1;
        } else {
          draft.selectedCategoryCount[categoryGroupId] -= 1;
        }
      })
    );
  }

  @Action(CategoryActions.ValidateCategories)
  validateCategories(
    ctx: StateContext<CategoryStateModel>,
    { categoryGroupId, valid }: CategoryActions.ValidateCategories
  ) {
    ctx.setState(
      produce((draft) => {
        draft.categoryGroups[categoryGroupId].valid = valid;
      })
    );
  }

  @Action(CategoryActions.GetSearchBarCategories)
  getSearchBarCategories(
    ctx: StateContext<CategoryStateModel>,
    { filter }: CategoryActions.GetSearchBarCategories
  ) {
    const state = ctx.getState();
    const res: Category[] = [];
    state.categoryGroups.forEach((group, i) => {
      res.push(
        ...group.categories
          .map((c) => {
            return { ...c, categoryGroupId: group.id };
          })
          .filter((category) =>
            this.translate.instant(this.CATEGORIES_PREFIX  + category.slug).toLowerCase().includes(filter.toLowerCase())
          )
          .sort((a, b) => {
            return +a.selected - +b.selected;
          })
      );
    });
    const resSelected = res.filter((c) => c.selected).sort((a, b) => this.translate.instant(this.CATEGORIES_PREFIX  + a.slug).localeCompare(this.translate.instant(this.CATEGORIES_PREFIX  + b.slug)));
    const resUnSelected = res.filter((c) => !c.selected).sort((a, b) => this.translate.instant(this.CATEGORIES_PREFIX  + a.slug).localeCompare(this.translate.instant(this.CATEGORIES_PREFIX  + b.slug)));
    ctx.patchState({ searchBarResults: [...resSelected, ...resUnSelected] });
  }
}
