import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {NavigationActions} from "@app/state/navigation/navigation.actions";
import {CatalogActions} from "@app/state/catalog/catalog.actions";

@Injectable({
  providedIn: 'root',
})
export class EnterStartPageResolver implements Resolve<any> {
  constructor(private store: Store) {}
  resolve(): Observable<any> {
    return this.store.dispatch([
      new NavigationActions.EnterStartPage()
    ])
  }
}
