<div class="loading-overlay" *ngIf="loading">
  <div class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="content" #content>

  <app-navbar></app-navbar>
  <router-outlet></router-outlet>
</div>

