import {Component, OnDestroy, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Store} from "@ngxs/store";
import {NavigationActions} from "@app/state/navigation/navigation.actions";
import {CatalogActions} from "@app/state/catalog/catalog.actions";

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit, OnDestroy {

  constructor(private translateService: TranslateService, public store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(new NavigationActions.SetTemplateFlow(false));
  }

  nextStep(nextStep: number) {
    this.store.dispatch(
      new CatalogActions.GoToStep(nextStep)
    );
  }

  ngOnDestroy() {
    this.store.dispatch([
      new NavigationActions.LeaveStartPage()
    ])
  }
}
