import { Component, OnInit} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import {Select, Store} from '@ngxs/store';
import { AuthActions } from 'src/app/state/auth/auth.actions';
import { AuthState } from 'src/app/state/auth/auth.state';
import { CatalogState } from 'src/app/state/catalog/catalog.state';
import {Router} from "@angular/router"
import {Observable} from "rxjs";
import {CatalogData} from "@app/models/catalog.model";
import {CatalogActions} from "@app/state/catalog/catalog.actions";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ResetCatalogModalComponent} from "@app/pages/core/navbar/reset-catalog-modal/reset-catalog-modal.component";

declare function runMatomo(...args: any[]): void;
declare function matomoTagManager(): void;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @ViewSelectSnapshot(AuthState.accessToken) authorized: string;
  @ViewSelectSnapshot(CatalogState.accountNumber) accountNumber: string;
  @Select(CatalogState.catalogData) catalogData$: Observable<CatalogData>;
  selected: UntypedFormControl;

  languages: any[] = [
    { value: 'en', viewValue: 'EN' },
    { value: 'de', viewValue: 'DE' }
  ];

  constructor(private store: Store, private translation: TranslateService, private router: Router, public dialog: MatDialog) {
    matomoTagManager();
    this.selected = new UntypedFormControl('en');
  }

  ngOnInit(): void {
    this.catalogData$.subscribe((data) => {
      if (data.catalogId) {
        if (data.user.userLang == 1) {
          this.selected = new UntypedFormControl('en');
        } else if (data.user.userLang == 0) {
          this.selected = new UntypedFormControl('de');
        }
        // run matomo tagging
      runMatomo(data.user.brand.accountNumber,data.user.userName);
      }
      // translate pages depend on above condition.
      this.translation.use(this.selected.value);
  })}

  // This will be deprecated after removing language picker from user view
  changeLanguage(lang: string) {
    this.translation.use(lang);
  }

  logout() {
    this.store.dispatch(new AuthActions.Logout());
    window.location.reload();
  }
  goToGetStarted(){
    if(this.authorized!=null) {
      this.store.dispatch(
        new CatalogActions.GoToStep(1)
      );
    }else{
      this.router.navigate(['/redirect','l']);
    }
  }

  openResetPopUp(){
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open(ResetCatalogModalComponent, dialogConfig);
  }

}
