import {
  HttpClient
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CategoryGroup } from '../models/category.model';

@Injectable({
  providedIn: 'root',
})
export class ExportTbOneService {
  baseUrl = environment.apiUrl + '/catalog';

  constructor(private http: HttpClient) {}

  getPanda(catalogId: number) {
    var offset = String( new Date().getTimezoneOffset() );
    return this.http.get(`${this.baseUrl}/${catalogId}/generate-panda?offset=` + offset, {
      responseType: 'blob',
      observe: 'response',
      withCredentials: !environment.disableCsrf
    });
  }

}
