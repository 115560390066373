<div class="confirm-modal">

  <div class="information">
    {{ "COLUMNS_MAPPINGS.NR_NOT_MAPPED_MODAL.ERROR_MSG" | translate }}
  </div>

  <div class="buttons">
    <div class="button-container">
      <app-button
        id="map_nr_warn_modal_btn_close"
        (click)="closeModal()"
        type="stroked"
        text="COLUMNS_MAPPINGS.NR_NOT_MAPPED_MODAL.TRY_AGAIN_BUTTON_TXT"
      ></app-button>
    </div>
  </div>
</div>
