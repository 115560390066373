import { UploadSetup } from 'src/app/models/catalog.model';
import { SaveOptions } from 'src/app/services/auth-helper.service';
import {ResetCatalogModalComponent} from "@app/pages/core/navbar/reset-catalog-modal/reset-catalog-modal.component";

export namespace CatalogActions {
  export class UploadCSV {
    static readonly type = 'CatalogActions.UploadCSV';

    constructor(public file: File) {}
  }
  export class DeleteCSV {
    static readonly type = 'CatalogActions.DeleteCSV';
  }
  export class GetCatalogState {
    static readonly type = 'CatalogActions.GetCatalogState';
  }

  export class SetCsvSettings {
    static readonly type = 'CatalogActions.SetCsvFiles';

    constructor(public settings: UploadSetup) {}
  }

  export class GetCsvSettingsOptions {
    static readonly type = 'CatalogActions.GetCsvSettingsOptions';
  }

  export class GetStatusBar {
    static readonly type = 'CatalogActions.GetStatusBar';
  }

  export class UpdateStatusBar {
    static readonly type = 'CategoryActions.UpdateStatusBar';
  }

  export class UpdatePreprocessingStatus {
    static readonly type = 'CatalogActions.UpdatePreprocessingStatus';
  }

  export class SetData {
    static readonly type = 'CatalogActions.SetData';

    constructor(public option: SaveOptions) {}
  }


  export class GoToStep {
    static readonly type = "CatalogActions.GoToStep";

    constructor(public step: number) {}
  }

  export class DownloadPandaTemplate {
    static readonly type = "CatalogActions.DownloadPandaTemplate";
  }

  export class SetFileType{
    static readonly type = "CatalogAction.SetFileType";
    constructor(public fileType: String) {}
  }

  export class SetCatalogRetailers{
    static readonly type ="CatalogAction.SetCatalogRetailers"
    constructor(public ids: number[]) {}
  }

  export class GetCatalogRetailers {
    static readonly type = "CatalogAction.GetCatalogRetailers"
  }

  export class ResetCatalog {
    static readonly type = "CatalogAction.ResetCatalog"
    constructor(public resetModelComponent: ResetCatalogModalComponent) {}

  }
}
