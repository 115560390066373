import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-changes-modal',
  templateUrl: './confirm-changes-modal.component.html',
  styleUrls: ['./confirm-changes-modal.component.scss'],
})
export class ConfirmChangesModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmChangesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close('close');
    this.reloadPage();
  }

  // Needed so you can unit test
  reloadPage() {
    window.location.reload();
  }

  nextStep() {
    this.dialogRef.close('continue');
  }
}
