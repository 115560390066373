<h2 *ngIf="!isInRecommendedMode" class="mandatory-columns--title">{{ "LABELS.MANDATORY" | translate }}</h2>
<h2 *ngIf="isInRecommendedMode" class="mandatory-columns--title">{{ "LABELS.RECOMMENDED" | translate }}</h2>
<div class="column column--title">
  <div class="name name--title"> {{ "COLUMNS_MAPPINGS.LABELS.TRADEBYTE_ATTRIBUTE" | translate }}</div>
  <div class="select select--title"> {{ "COLUMNS_MAPPINGS.LABELS.COLUMN_NAME" | translate }}</div>
</div>
<div class="column mandatory-column" *ngFor="let column of mandatoryColumns; let i = index">
  <div class="name">
    <div *ngIf="column.arrayIndex == 0 || column.arrayIndex == -1">{{ column | mappingName }}</div>
    <div class="pandaColumnName">{{ column.displayColumn }}</div>
  </div>

  <div class="select">
    <mat-select
      id="map_list_{{column.columnName}}"
      [(value)]="column.option" #singleSelect
      [ngClass]="{'invalid': column.option === -1 && popupOpened && !isInRecommendedMode}"
      (click)="initializeFilteredList()"
      (selectionChange)="changeIsUserMapped($event, column)"
      disableOptionCentering>
      <mat-option
        [value]="-1"
        [disabled]="!shouldDisplayPleaseSelect(column)"
        [class.hide]="shouldDisplayPleaseSelect(column)">{{ "COLUMNS_MAPPINGS.LABELS.SELECT_OPTION" | translate}}</mat-option>
      <mat-option>
        <ngx-mat-select-search
          id="map_input_search_list_{{column.columnName}}"
          [formControl]="columnFilterCtrl"
          placeholderLabel='{{"CATALOG_CATEGORIES.SEARCH_BAR_PLACEHOLDER" | translate }}'
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        id="map_list_{{column.columnName}}_option_{{option}}"
        *ngFor="let option of columnNames"
        [value]= "getValue(option,column)"
        [disabled]="!isFiltered(option)"
        [class.hide]="!isFiltered(option)"
      >{{option}}</mat-option>

    </mat-select>
    <div class="checkmark" *ngIf="column.option !== -1 && !isInRecommendedMode">
      <app-icon name="checkmark_circle_24_regular" class="primary"></app-icon>
    </div>
    <div id="map_btn_add_{{column.columnName}}" class="add-array-item-button" (click)="addMapping(column, i)" *ngIf="shouldDisplayAdd(column)">
      <button mat-stroked-button ><app-icon name="add_24_filled" class="primary"></app-icon></button>
    </div>
    <div id="map_btn_remove_{{column.columnName}}" class="remove-array-item-button" (click)="deleteMapping(column, i)" *ngIf="shouldDisplayDelete(column)">
      <button mat-stroked-button ><app-icon name="delete_24_regular" class="primary"></app-icon></button>
    </div>
  </div>
</div>
