import {
  HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Retailer} from "@app/models/retailer.model";

import { plainToClass } from 'class-transformer';
import {map, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { CatalogData, UploadSetup } from '../models/catalog.model';
import { StatusBar } from '../models/status-bar.model';
import { httpRequestOptions } from "../../constants";

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  baseUrl = environment.apiUrl + '/catalog';

  constructor(private http: HttpClient) {}

  deleteCSV(catalogId: number) {
    return this.http.delete(`${this.baseUrl}/${catalogId}/cancel-upload`, httpRequestOptions).pipe(
      map((response) => {
        return plainToClass(CatalogData, response);
      })
    );
  }

  getCategoryState() {
    return this.http.get(`${this.baseUrl}/`, httpRequestOptions).pipe(
      map((response) => {
        return plainToClass(CatalogData, response);
      })
    );
  }

  saveCsvSettings(settings: UploadSetup) {
    return this.http.post<UploadSetup>(`${this.baseUrl}/settings`, {
      ...settings,
      isClosed: false,
    }, httpRequestOptions);
  }

  uploadCatalog(file: File, catalogId: number) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('catalogId', `${catalogId}`);
    return this.http.post(`${this.baseUrl}/upload`, formData, {
      reportProgress: true,
      observe: 'events',
      withCredentials: !environment.disableCsrf,
    });
  }

  uploadProgressBarStatus( catalogId: number) {
    return this.http.get(`${this.baseUrl}/upload/${catalogId}`, httpRequestOptions);
  }

  getStatusBar() {
    return this.http.get<Node[]>(`${this.baseUrl}/states`, httpRequestOptions).pipe(
      map((response: Node[]) => {
        const statusBar = {
          nodes: response,
        };
        return plainToClass(StatusBar, statusBar);
      })
    );
  }
  saveCatalogData(catalogStateId: number) {
    return this.http.post(`${this.baseUrl}/state`, {catalogStateId}, httpRequestOptions).pipe(
      map((response) => {
        return plainToClass(CatalogData, response);
      })
    );
  }

  setFileType(fileType: String){
    return this.http.post(`${this.baseUrl}/file-type`, {fileType},
      httpRequestOptions);
  }

  setCatalogRetailers(ids: number[]){
    return this.http.post(`${environment.apiUrl}/retailers`, {ids},
      httpRequestOptions);
  }

  getCatalogRetailers(): Observable<Retailer[]> {
    return this.http.get<Retailer[]>(
      `${environment.apiUrl}/retailers`
    );
  }

  resetCatalog(){
    return this.http.post(`${this.baseUrl}/reset`,{},httpRequestOptions);
  }
}
