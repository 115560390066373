import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AuthState } from '../state/auth/auth.state';
import { Store } from '@ngxs/store';
import { AuthActions } from '../state/auth/auth.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private headerName = 'Authorization';
  @SelectSnapshot(AuthState.accessToken) accessToken: string;

  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('login')) {
      const nextRequest = this.addAuthenticationToken(request);
      return next.handle(nextRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.store.dispatch(new AuthActions.Logout());
          }
          throw error;
        })
      );
    }
    return next.handle(request);
  }

  addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If access token is null this means that user is not logged in
    if (!this.accessToken) {
      return request;
    }

    // Clone the request, because the original request is immutable
    return request.clone({
      headers: request.headers.set(
        this.headerName,
        `Bearer ${this.accessToken}`
      ),
    });
  }
}
