import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() text: string;
  @Input() color: 'primary' | 'accent' | 'warn' | '' = 'primary';
  @Input() disabled: boolean = false;
  @Input() type: 'raised' | 'stroked' = 'raised';

  constructor() { }

  ngOnInit(): void {
  }

}
