<div class="category-container">
  <div id="com_btn_back" *ngIf="!onStartPage" class="back" (click)="navigate()">
    <app-icon name="chevron_left_16_regular" class="primary"></app-icon>
    <div class="text">
      {{ "LABELS.BACK" | translate }}
    </div>
  </div>
  <div class="category">
    <router-outlet></router-outlet>
  </div>
</div>
