<div class="upload-catalog">
  <h1 class="title">{{ "UPLOAD_CATALOG.TITLE" | translate }}</h1>
  <div class="text">{{ "UPLOAD_CATALOG.SUB_TEXT" | translate }}</div>
  <h1 class="csv-title">{{ "UPLOAD_CATALOG.CSV_TITLE" | translate }}</h1>
  <div class="csv-form">
    <app-import-settings
      [control]="form.get('csvSeparator')"
      label="LABELS.SEPARATOR"
      settingType="csv_separator"
      [options]="csvSettingsOptions?.separators"
    ></app-import-settings>
    <app-import-settings
      [control]="form.get('textSeparator')"
      label="LABELS.TEXT_SEPARATOR"
      settingType="text_separator"
      [options]="csvSettingsOptions?.textSeparators"
    ></app-import-settings>
    <app-import-settings
      [control]="form.get('charset')"
      label="LABELS.CHARSET"
      settingType="charset"
      [options]="csvSettingsOptions?.charsets"
    ></app-import-settings>
  </div>
  <app-upload-csv></app-upload-csv>
</div>
<app-catalog-footer (event)="nextStep($event)"></app-catalog-footer>
