import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Auth } from '../models/auth.model';
import { StatusBar } from '../models/status-bar.model';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  baseUrl = environment.apiUrl + '/catalog';

  constructor(private http: HttpClient) {}


}
